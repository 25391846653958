import { unitize } from "@abs-safety/lock-book-web-ui";
import styled, { css } from "styled-components";

export const Label = styled.label<{ bold?: boolean }>`
    display: inline-block;
    line-height: ${(props) => props.theme.typography.lineHeight.p};
    margin-bottom: ${unitize(10)};
    ${(props) =>
        props.bold === true &&
        css`
            font-weight: ${(props) => props.theme.typography.weight.bold};
        `}
`;

import * as React from "react";
import * as yup from "yup";
import { action, makeObservable, observable } from "mobx";
import { isNullish } from "../utils/isNullish";
import { isNotEmptyString } from "../utils/isNotEmptyString";

export interface Config {
    tenant: string;
    docu: {
        frontend_url: string;
        api_url: string;
        info_url: string;
        reportIssue_url: string;
        path_prefix?: string;
        api_path_prefix?: string;
    };
    draw: { frontend_url: string; info_url: string };
    hub: { frontend_url: string; api_url: string; path_prefix?: string };
    frameworks: { webui: any };
    imprint_url: string;
    support_url: string;
    tos_url: string;
}

const configSchema = yup
    .object<Config>({
        tenant: yup.string().required(),
        docu: yup
            .object({
                frontend_url: yup.string().required(),
                api_url: yup.string().required(),
                info_url: yup.string().required(),
                reportIssue_url: yup.string().required(),
                path_prefix: yup.string().default("/doku"),
                api_path_prefix: yup.string().default("/doku/api"),
            })
            .required(),
        draw: yup
            .object({
                frontend_url: yup.string().required(),
                info_url: yup.string().required(),
            })
            .required(),
        hub: yup
            .object({
                frontend_url: yup.string().required(),
                api_url: yup.string().required(),
                path_prefix: yup.string().default(""),
            })
            .required(),
        frameworks: yup
            .object({
                webui: yup.mixed().required(),
            })
            .required(),
        imprint_url: yup.string().required(),
        support_url: yup.string().required(),
        tos_url: yup.string().required(),
    })
    .required();

const ConfigContext = React.createContext<Config>(undefined as unknown as Config);

interface ConfigProviderProps {
    configUrl: string;
    configId?: string;
    fallback?: React.ComponentType<unknown>;
    /** only for tests */
    mockedConfig?: Config;
}

const getStaticConfig = (id?: string): Config | undefined => {
    if (id === undefined) return undefined;

    const staticConfigJSON = document.getElementById(id)?.textContent ?? undefined;
    if (staticConfigJSON === undefined) return undefined;

    return configSchema.validateSync(replaceEnvVars(JSON.parse(staticConfigJSON)));
};

const cleanPath = (path: string): string => path.replace(/([^:]\/)\/+/g, "$1");
export const useConfig = (): Config & { getFileSrc: <T extends string | null | undefined>(path: T) => T } => {
    const config = React.useContext(ConfigContext);
    const getFileSrc = <T extends string | null | undefined>(path: T): T => {
        if (isNullish(path) || path === "") {
            return path;
        }

        try {
            const url = new URL(path, config.docu.api_url);
            let cleanedPath = url.pathname;

            // Ensure the path starts with the api_path_prefix
            cleanedPath = cleanedPath.startsWith(config.docu.api_path_prefix!)
                ? cleanedPath
                : `${config.docu.api_path_prefix}${cleanedPath}`;

            return `${cleanPath(`${url.origin}${cleanedPath}`)}${url.search}${url.hash}` as T;
        } catch {
            return cleanPath(`${config.docu.api_url}${path}`) as T;
        }
    };

    return { ...config, getFileSrc };
};

// TODO remove after rebranding (when hard-coded config.json is gone)
const replaceEnvVars = (config: Config): Config => {
    /**".dev", ".staging", ""  */
    const stageUrlSegment = window.location.host.match(/^docu\.(.*?)lock-book\.com/)?.[1] ?? "";

    // Object with placholder keys -> values
    const env: Record<string, string | undefined> = {
        LOCK_BOOK_API_URL: process.env.REACT_APP_API_URL,
        LOCK_BOOK_HUB_URL: process.env.REACT_APP_HUB_URL,
        LOCK_BOOK_HUB_API_URL: process.env.REACT_APP_HUB_API_URL,
        LOCK_BOOK_DRAW_URL: `https://draw.${stageUrlSegment}lock-book.com`,
    };

    // replace (immutable) all values (deep nested) in config like `{{LOCK_BOOK_HUB_URL}}` -> with its env value
    const replaced = JSON.parse(
        JSON.stringify(config).replace(/{{(.*?)}}/g, (_match, key: string) => {
            const value = env[key];
            if (value === undefined) {
                throw new Error(`Environment variable ${key} not found`);
            }
            return value;
        })
    );

    return replaced;
};

const getMockedConfig = (mockedConfig: Config | undefined): Config | undefined => {
    if (mockedConfig === undefined) return undefined;
    if (process.env.NODE_ENV !== "test") {
        throw new Error("Mocked config is only allowed in test environment");
    }
    return configSchema.validateSync(replaceEnvVars(mockedConfig));
};

export const ConfigProvider = ({
    children,
    fallback: Fallback,
    configUrl,
    configId,
    mockedConfig,
}: React.PropsWithChildren<ConfigProviderProps>) => {
    const _mockedConfig = getMockedConfig(mockedConfig);
    const staticConfig = _mockedConfig ?? getStaticConfig(configId);
    const [config, setConfig] = React.useState<Config | undefined>(staticConfig);

    React.useEffect(() => {
        if (staticConfig !== undefined) return;

        fetch(configUrl)
            .then((response) => response.json())
            .then((config) => replaceEnvVars(config))
            .then((config) => configSchema.validate(config))
            .then((config) => {
                console.log("config loaded", config);
                // session.setApiUrl(config.docu.api_url);
                configStore.setConfig(config);
                setConfig(config);
            });
    }, [configUrl]);

    if (config === undefined) {
        return Fallback !== undefined ? <Fallback /> : null;
    }
    if (configStore.config === undefined) {
        configStore.setConfig(config);
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

/** MobX Store to provide an observable way to access config values */
class ConfigStore {
    config: Config | undefined = undefined;

    constructor() {
        makeObservable(this, {
            config: observable,
            setConfig: action,
        });
    }

    setConfig = (config: Config) => {
        this.config = config;
    };
}
export const configStore = new ConfigStore();

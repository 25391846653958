import {
    Card,
    ColumnSizes,
    DropdownWithButton,
    GridColumns,
    IconArea,
    IconEdit,
    IconHammer,
    IconTrash,
    IconUserCircle,
    IconWrench,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { IBuildingAreaRead } from "../../../entities/BuildingArea";
import { formatDate } from "../../../utils/formatDate";
import { useConfig } from "../../../config/Config";

interface CardBuildingAreaProps {
    buildingArea: IBuildingAreaRead;
    disabled?: boolean;
    columnSizes: ColumnSizes;
    onDelete: () => void;
    onEdit: () => void;
    onNewDocumentationAssembly: () => void;
    onNewDocumentationMaintenance: () => void;
}

export const CardBuildingArea: FunctionComponent<CardBuildingAreaProps> = (props: CardBuildingAreaProps) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [subtitle, setSubtitle] = useState("");
    const history = useHistory();
    const config = useConfig();
    const amountAssemblies = props.buildingArea.amountAssemblies;
    const amountMaintenances = props.buildingArea.amountMaintenances;
    const theme = useTheme();

    useEffect(() => {
        const parts: string[] = [];
        if (amountAssemblies && amountAssemblies > 0) {
            // TODO: Singular form if amount == 1 (maybe to wait for i18n?)
            parts.push(`${amountAssemblies} Montagen`);
        }
        if (amountMaintenances && amountMaintenances > 0) {
            // TODO: Singular form if amount == 1 (maybe to wait for i18n?)
            parts.push(`${amountMaintenances} Wartungen`);
        }
        setSubtitle(parts.length ? parts.join(", ") : "Noch keine Dokumentation");
    }, [amountAssemblies, amountMaintenances]);

    const onCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!buttonRef.current) {
            // this should actually never happen, that user clicks faster than React can initialize buttonRef...
            return;
        }
        // we have to check if user clicked on button (cardClick should than be ignored)
        // we have to do it like this, instead of Button onClick e.stopPropagation(), as the click event should proopagate in order
        // to close other Dropdowns which might be opened (window event listener)
        if (e.target === buttonRef.current || buttonRef.current.contains(e.target as HTMLElement)) {
            // don't call onClick, when user clicked on Dropdown Options Icon (to open Dropdown)
            return;
        }

        history.push(
            `${config.docu.path_prefix}/buildings/${props.buildingArea.buildingId}/areas/${props.buildingArea.id}`
        );
    };

    return (
        <Card
            className="card uf-areaCard"
            onClick={onCardClick}
            disabled={props.disabled}
            dropdownItems={[
                {
                    icon: <IconEdit />,
                    text: "Bearbeiten",
                    onClick: props.onEdit,
                },
                {
                    icon: <IconTrash />,
                    text: "Löschen",
                    textColor: theme.color.error,
                    onClick: props.onDelete,
                },
            ]}
        >
            <S.CardIcon>
                <IconArea width={15} />
            </S.CardIcon>

            <GridColumns columnSizes={props.columnSizes} style={{ height: unitize(90), alignItems: "center" }}>
                {/* Column */}
                <S.Col1 className="overflow-hidden">
                    <h4 className="truncate">{props.buildingArea.name}</h4>
                    <p>{subtitle}</p>
                </S.Col1>

                {/* Column */}
                <div>
                    <S.LastColumn>
                        <S.LastUpdated>
                            {props.buildingArea.lastUpdatedBy?.firstName &&
                            props.buildingArea.lastUpdatedBy?.lastName ? (
                                <>
                                    <S.UserIcon className="d-xs-none d-sm-block">
                                        <IconUserCircle
                                            firstname={props.buildingArea.lastUpdatedBy.firstName}
                                            lastname={props.buildingArea.lastUpdatedBy.lastName}
                                        />
                                    </S.UserIcon>
                                    <S.LastUpdatedData>
                                        <p>{formatDate(props.buildingArea.updatedAt)}</p>
                                        von {props.buildingArea.lastUpdatedBy.firstName}{" "}
                                        {props.buildingArea.lastUpdatedBy.lastName}
                                    </S.LastUpdatedData>
                                </>
                            ) : (
                                <S.Date>{formatDate(props.buildingArea.updatedAt)}</S.Date>
                            )}
                        </S.LastUpdated>
                        <S.ButtonWrapper ref={buttonRef} className="d-xs-none d-lg-block uf-cardDropdownButton">
                            <DropdownWithButton
                                width={230}
                                buttonText="Dokumentation anlegen"
                                items={[
                                    {
                                        icon: <IconHammer />,
                                        text: "Montage",
                                        onClick: props.onNewDocumentationAssembly,
                                    },
                                    {
                                        icon: <IconWrench />,
                                        text: "Wartung",
                                        onClick: props.onNewDocumentationMaintenance,
                                    },
                                ]}
                            />
                        </S.ButtonWrapper>
                    </S.LastColumn>
                </div>

                {/* Column */}
            </GridColumns>
        </Card>
    );
};

//#region styles
const S = {
    CardIcon: styled.div`
        position: absolute;
        left: ${unitize(10)};
        top: ${unitize(10)};
    `,
    Menu: styled.div`
        position: absolute;
        right: ${unitize(10)};
        top: ${unitize(10)};
    `,
    GridWrapper: styled.div`
        display: grid;
        align-items: center;
        height: ${unitize(90)};
        grid-template-columns: repeat(12, 1fr);
        column-gap: ${unitize(30)};
    `,
    Col1: styled.div`
        margin-left: ${unitize(20)};
        p {
            font-size: ${unitize(14)};
        }
    `,
    Date: styled.div`
        font-size: ${(props) => props.theme.typography.size.pBig}rem;
        display: flex;
        align-items: center;
    `,
    LastUpdated: styled.div`
        display: flex;
        align-items: center;
    `,
    LastUpdatedData: styled.div`
        display: flex;
        flex-direction: column;
    `,
    UserIcon: styled.div`
        margin-right: ${unitize(10)};
    `,
    LastColumn: styled.div`
        display: flex;
        justify-content: space-between;
    `,
    ButtonWrapper: styled.div`
        text-align: right;
        padding-right: ${unitize(30)};
    `,
};
//#endregion styles

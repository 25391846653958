import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { session } from "../../session/Session";
import { isDefined } from "../../utils/isDefined";
import Error403 from "../errors/Error403";
import { adminStore } from "./AdminStore";
import ProductFormPage from "./Products/ProductFormPage";
import ProductListPage from "./Products/ProductListPage";
import { useConfig } from "../../config/Config";

/**
 * Page: Admin
 */
const Admin: FunctionComponent = () => {
    const { path } = useRouteMatch();
    const config = useConfig();

    useEffect(() => {
        adminStore.init();
        adminStore.setAdminPageIsOpened(true);
        return () => {
            adminStore.setAdminPageIsOpened(false);
        };
    }, []);

    //#region render
    if (isDefined(session.currentUser) && !session.hasAbsAdminRights && !session.hasCompanyAdminRights) {
        return <Error403 />;
    }
    return (
        <Switch>
            <Route path={`${path}/products/create`}>
                <ProductFormPage pageType="create" />
            </Route>
            <Route path={`${path}/products/edit`}>
                <ProductFormPage pageType="edit" />
            </Route>
            <Route path={`${path}/products`} exact component={ProductListPage} />
            <Route path={`${path}*`}>
                <Redirect to={`${config.docu.path_prefix}/admin/products`} />
            </Route>
        </Switch>
    );
};
//#endregion

export default observer(Admin);

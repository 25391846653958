import { ApiResponse } from "../api/ApiService";
import { AreaApi } from "../api/AreaApi";
import { BuildingApi } from "../api/BuildingApi";
import { DocumentationApi, IArticleOverviewItemDocu } from "../api/DocumentationApi";
import {
    documentationRepo,
    IDocumentationCreateCertificate,
    IDocumentationRead,
    IDocumentationUpdate,
    IDocumentationWrite,
} from "../entities/Documentation";
import { notificationHandler } from "../session/NotificationHandler";
import { session } from "../session/Session";
import { isDefined } from "../utils/isDefined";
import { DataService } from "./abstract/DataService";
import { dataLayerService } from "./DataLayerService";
import { documentService } from "./DocumentService";

type WaitingForKey = "addSignature" | "sendMaintenanceReminder";
class DocumentationService extends DataService<
    WaitingForKey,
    IDocumentationRead,
    IDocumentationWrite | IDocumentationUpdate
> {
    apiService = new DocumentationApi();
    areaApi = new AreaApi();
    buildingApi = new BuildingApi();
    repo = documentationRepo;

    constructor() {
        super({
            addSignature: false,
            sendMaintenanceReminder: false,
        });
    }

    fetchByArea(buildingAreaId: number): Promise<ApiResponse<IDocumentationRead[]>> {
        return this.resolveAsAction({
            promise: () => this.areaApi.fetchDocumentations(buildingAreaId),
            waitingForKey: "fetch",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(result.result.filter((documentation) => documentation.isActive));
                }

                return result;
            },
        });
    }

    fetchByBuildingId(buildingId: number): Promise<ApiResponse<IDocumentationRead[]>> {
        return this.resolveAsAction({
            promise: () => this.buildingApi.getDocumentations(buildingId),
            waitingForKey: "fetch",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(
                        result.result.filter((documentation) => documentation.isActive && documentation.isCompleted)
                    );
                }

                return result;
            },
        });
    }

    createByArea(areaId: number, body: IDocumentationWrite): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () =>
                this.areaApi.post<IDocumentationRead, IDocumentationWrite>(`/${areaId}/documentations`, body),
            waitingForKey: "create",
            action: (result) => {
                if (isDefined(result.result)) {
                    this.mergeList(result.result);
                }

                return result;
            },
        });
    }

    deactivate(docuId: number): Promise<ApiResponse<IArticleOverviewItemDocu>> {
        return this.resolveAsAction({
            promise: () =>
                this.apiService.put(`/${docuId}`, {
                    isActive: false,
                }),
            waitingForKey: "delete",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.drop(docuId);
                }

                return response;
            },
        });
    }

    addSignature(
        docuId: number,
        signature: IDocumentationRead["signature"],
        useUserSignature = false
    ): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.updateSignature(docuId, signature, useUserSignature),
            waitingForKey: "addSignature",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }
                return response;
            },
        });
    }

    completeDocumentation(docuId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.completeDocumentation(docuId),
            waitingForKey: "update",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                    dataLayerService.emitEvent("conversion", {
                        category: response.result.type,
                        action: "complete_documentation",
                        label: response.result.name,
                        payload: session.currentUser?.company.id,
                    });
                    documentService.fetchByDocumentation(docuId);
                }

                return response;
            },
        });
    }

    createCertificate(docuId: number, data: IDocumentationCreateCertificate): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.postDocumentationCertificate(docuId, data),
            waitingForKey: "update",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    documentService.documentationToDocument[docuId] = response.result.documents!.map(
                        (document) => document.id
                    );
                    documentService.mergeList(response.result.documents);
                }

                return response;
            },
        });
    }

    reopenDocumentation(docuId: number): Promise<ApiResponse<IDocumentationRead>> {
        return this.resolveAsAction({
            promise: () => this.apiService.reopenDocumentation(docuId),
            waitingForKey: "update",
            action: (response) => {
                if (isDefined(response.result)) {
                    this.mergeList(response.result);
                }

                return response;
            },
        });
    }

    sendMaintenanceReminder(documentationId: number) {
        return this.resolveAsAction({
            promise: () => this.apiService.sendMaintenanceReminder(documentationId),
            waitingForKey: "sendMaintenanceReminder",
            action: async (result) => {
                if (result.response !== null && result.response.ok && result.result !== null) {
                    notificationHandler.addNotification({
                        title: "Kalendereintrag",
                        description: "Kalendereintrag wurde an Deine E-Mail Adresse verschickt",
                        type: "success",
                    });
                }
                return result;
            },
        });
    }
}

export const documentationService = new DocumentationService();

import { Dropdown, IconArrowRotation, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { AnswerBaseProps } from "./QuestionRow";

type AnswerYesNoProps = AnswerBaseProps;
interface DropdownItem {
    value: string;
    text: string;
}
export const yesNoAnswerValueToText: Record<"0" | "1" | "2", string> = {
    "0": "Nein",
    "1": "Ja",
    "2": "Keine Angabe",
};

/** in right order */
const dropdownItems: DropdownItem[] = [
    { value: "1", text: yesNoAnswerValueToText[1] },
    { value: "0", text: yesNoAnswerValueToText[0] },
    { value: "2", text: yesNoAnswerValueToText[2] },
];

/**
 * Dropdown with value-text pairs:
 * ```
 *  {
 *    '0': 'Nein',
 *    '1': 'Ja',
 *    '2': 'Keine Angabe',
 *  }
 * ```
 */
const AnswerYesNo: FunctionComponent<AnswerYesNoProps> = (props: AnswerYesNoProps) => {
    // toggle dropdown
    const [isOpen, setOpen] = useState(false);
    const onToggle = (isNowOpen: boolean) => {
        setOpen(isNowOpen);
    };

    return (
        <Dropdown
            items={dropdownItems.map((item) => {
                return {
                    text: item.text,
                    onClick: () => {
                        props.onChange(item.value);
                    },
                };
            })}
            dropdownAlign="right"
            width={270}
            onToggle={onToggle}
            disabled={props.disabled}
            toggleNode={
                <S.ValueWithIcon>
                    {props.hasDifferentValues === false ? (
                        <S.Placeholder>???</S.Placeholder>
                    ) : (
                        <span>
                            {
                                dropdownItems.find(
                                    // toString() as API parses value to number even though it should be string
                                    (item) => item.value === props.value.toString()
                                )?.text
                            }
                        </span>
                    )}
                    {props.disabled !== true && <IconArrowRotation rotate={isOpen} />}
                </S.ValueWithIcon>
            }
        />
    );
};

export default AnswerYesNo;

//#region styles
const S = {
    ValueWithIcon: styled.span`
        display: flex;
        align-items: center;
        font-weight: ${(props) => props.theme.typography.weight.bold};
        gap: ${unitize(10)};
    `,
    Placeholder: styled.span`
        color: ${(props) => props.theme.color.darkgrey};
        font-weight: ${(props) => props.theme.typography.weight.regular};
    `,
};
//#endregion styles

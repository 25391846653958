import {
    Button,
    ColumnSizes,
    FieldEmpty,
    IconHammer,
    IconPlus,
    IconWrench,
    ListSortable,
    ListSortableRef,
    SideModal,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent, useRef, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { DocumentationType, IDocumentationRead } from "../../../entities/Documentation";
import { getController } from "../../../stores/controller/ControllerFactory";
import { layout } from "../../../utils/layout";
import Edit from "../../Documentation/subviews/Edit";
import { AreaController } from "../AreaController";
import CardDocumentation from "../components/CardDocumentation";

const listColumnSizes: ColumnSizes = { xs: [7, 0, 0, 5], md: [3, 2, 3, 4] };

interface ViewDocumentationsProps {
    type: DocumentationType;
}

export type SortProgressFunctionItem = Pick<IDocumentationRead, "documentationItemIds" | "amountDocumented">;

/** exported as extra function to unit test it */
export function sortProgressFunction(a: SortProgressFunctionItem, b: SortProgressFunctionItem): number {
    const lengthA = a.documentationItemIds.length > 0 ? a.documentationItemIds.length : 1;
    const lengthB = b.documentationItemIds.length > 0 ? b.documentationItemIds.length : 1;
    const progressA = a.documentationItemIds.length === 0 ? 0 : (100 / lengthA) * a.amountDocumented;
    const progressB = b.documentationItemIds.length === 0 ? 0 : (100 / lengthB) * b.amountDocumented;
    const progressABool = progressA !== 0;
    const progressBBool = progressB !== 0;

    if (progressABool && !progressBBool) return 1;
    if (progressBBool && !progressABool) return -1;
    if (!progressABool && !progressBBool) return 0;
    return progressA - progressB;
}

const ViewDocumentations: FunctionComponent<ViewDocumentationsProps> = (props: ViewDocumentationsProps) => {
    const { controller } = getController(AreaController);
    const history = useHistory();
    const { url } = useRouteMatch();
    const params: {
        buildingAreaId: string;
    } = useParams();
    const buildingAreaId = parseInt(params.buildingAreaId);
    const listSortableRef = useRef<ListSortableRef | null>(null);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditDocumentationId, setModalEditDocumentationId] = useState<number | undefined>(undefined);
    const [modalEditBuildingId, setModalEditBuildingId] = useState<number | undefined>(undefined);
    const theme = useTheme();

    const onCreateDocumentation = (buildingAreaId: number, type: DocumentationType) => {
        const name = `${type === "assembly" ? "Montage" : "Wartung"} ${moment(new Date()).format("YYYY-MM-DD")}`;
        listSortableRef.current?.clearSorting();
        controller.createDocumentation(buildingAreaId, type, name);
    };

    const cardDoucmentationOnEdit = (docuId: number, buildingId: number | null | undefined) => {
        if (buildingId !== null && buildingId !== undefined) {
            setModalEditBuildingId(buildingId);
        }

        setModalEditDocumentationId(docuId);
        setModalEdit(true);
    };

    return (
        <S.ViewDocumentations>
            <S.CreateButton>
                <Button
                    disabled={
                        controller.waitingFor.loadDocumentations === true ||
                        controller.waitingFor.createDocumentation === true ||
                        controller.waitingFor.deleteDocumentation === true
                    }
                >
                    <button
                        onClick={() => onCreateDocumentation(buildingAreaId, props.type)}
                        className={"uf-createButton"}
                    >
                        {props.type === "maintenance" ? "Wartung anlegen" : "Montage anlegen"}{" "}
                        <IconPlus color={theme.color.white} />
                    </button>
                </Button>
            </S.CreateButton>

            {controller.waitingFor.loadDocumentations !== false ? (
                <FieldLoading
                    text={props.type === "maintenance" ? "Wartungen werden geladen..." : "Montagen werden geladen ..."}
                />
            ) : controller.documentations.filter((docu) => docu.type === props.type).length === 0 &&
              controller.waitingFor.createDocumentation === false ? (
                props.type === "maintenance" ? (
                    <FieldEmpty icon={<IconWrench height={48} width={48} />} text="Keine Wartung vorhanden" />
                ) : (
                    <FieldEmpty icon={<IconHammer height={48} width={48} />} text="Keine Montage vorhanden" />
                )
            ) : (
                <>
                    <ListSortable
                        ref={listSortableRef}
                        headerStyle={{ padding: unitize(10) }}
                        listStyle={{ display: "grid", gridGap: unitize(10) }}
                        columnSizes={listColumnSizes}
                        items={controller.documentations}
                        columns={[
                            {
                                title: "Name",
                                sortProperty: "name",
                            },
                            { title: "Angelegt am", sortProperty: "createdAt" },
                            {
                                title: "Letzte Änderung",
                                sortProperty: "updatedAt",
                            },
                            {
                                title: "Fortschritt",
                                sortFunction: sortProgressFunction,
                            },
                        ]}
                    >
                        {(sortedList) => (
                            <>
                                {controller.waitingFor.createDocumentation !== false && (
                                    <FieldLoading
                                        height={unitize(110)}
                                        text={
                                            props.type === "maintenance"
                                                ? "Wartung wird erstellt..."
                                                : "Montage wird erstellt ..."
                                        }
                                    />
                                )}
                                {sortedList
                                    .filter((docu) => docu.type === props.type)
                                    .map((documentation) => (
                                        <CardDocumentation
                                            key={documentation.id}
                                            documentation={documentation}
                                            disabled={controller.waitingFor.deleteDocumentation === documentation.id}
                                            type={props.type}
                                            columnSizes={listColumnSizes}
                                            onCardClick={() =>
                                                history.push(`${url}/documentations/${documentation.id}`)
                                            }
                                            onEdit={() =>
                                                cardDoucmentationOnEdit(documentation.id, documentation.buildingId)
                                            }
                                            onDelete={() => controller.deleteDocumentation(documentation)}
                                        />
                                    ))}
                            </>
                        )}
                    </ListSortable>
                </>
            )}
            {modalEdit && modalEditDocumentationId !== undefined && modalEditBuildingId !== undefined && (
                <SideModal
                    className="uf-sideModal"
                    isOpen
                    onCloseClick={() => setModalEdit(false)}
                    style={{
                        top: `${layout.header.height}px`,
                        height: `calc(100% - ${layout.header.height}px)`,
                    }}
                    views={[
                        {
                            key: "edit",
                            width: 750,
                            component: (
                                <Edit documentationId={modalEditDocumentationId} buildingId={modalEditBuildingId} />
                            ),
                        },
                    ]}
                />
            )}
        </S.ViewDocumentations>
    );
};

export default observer(ViewDocumentations);

//#region styles
const S = {
    ViewDocumentations: styled.div``,
    CreateButton: styled.div`
        margin-bottom: ${unitize(30)};
    `,
};
//#endregion styles

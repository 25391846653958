import {
    Button,
    GridColumns,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { Form, FormikProps } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import FormInput from "../../../components/FormInput";
import { FormWrapperStyled } from "./Create";
import { FormValues } from "./Step2";

interface Step2FormProps extends FormikProps<FormValues> {
    onCloseClick: () => void;
    previousStep: () => void;
}

const Step2Form: FunctionComponent<Step2FormProps> = (props: Step2FormProps) => (
    <Form>
        <Modal size="lg" isOpen onCloseClick={props.onCloseClick}>
            <ModalHeader title="Objekt anlegen" subtitle="Schritt 2/2" />
            <ModalContent>
                <FormWrapperStyled>
                    <S.Label>
                        Ansprechpartner <S.LabelSuffix>(optional)</S.LabelSuffix>
                    </S.Label>
                    <FormInput type="text" size="sm" name="managerSalutation" label="Anrede" />
                    <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
                        <div>
                            <FormInput label="Vorname" name="managerFirstName" type="text" size="sm" />
                        </div>
                        <div>
                            <FormInput label="Nachname" name="managerLastName" type="text" size="sm" />
                        </div>
                    </GridColumns>
                    <FormInput label="Funktion" name="managerRole" type="text" size="sm" />
                    <GridColumns columnSizes={{ xs: [1], sm: [1, 1] }}>
                        <div>
                            <FormInput label="Telefon" name="managerPhone1" type="text" size="sm" />
                        </div>
                        <div>
                            <FormInput label="Fax" name="managerFax" type="text" size="sm" />
                        </div>
                    </GridColumns>
                    <FormInput label="Handy" name="managerPhone2" type="text" size="sm" />
                    <FormInput label="Email" name="managerEmail" type="text" size="lg" />
                </FormWrapperStyled>
            </ModalContent>

            <ModalFooter>
                <Button disabled={!props.isValid}>
                    <button type="submit" onClick={props.submitForm} className={"uf-createBuildingDone"}>
                        Fertig
                    </button>
                </Button>
                <Button variant="text" color="black">
                    <button type="button" onClick={props.previousStep}>
                        Zurück
                    </button>
                </Button>
            </ModalFooter>
        </Modal>
    </Form>
);

export default observer(Step2Form);

//#region styles
const S = {
    LabelSuffix: styled.span`
        font-size: ${unitize(19)};
        font-weight: ${(props) => props.theme.typography.weight.regular};
    `,
    Label: styled.h4`
        margin-bottom: ${unitize(10)};
    `,
};
//#region styles

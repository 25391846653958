import {
    Card,
    ColumnSizes,
    GridColumns,
    IconCopy,
    IconHammer,
    IconUserCircle,
    IconWrench,
    media,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { IDocumentationRead } from "../../../entities/Documentation";
import { documentationSharingHashService } from "../../../services/DocumentationSharingHashService";
import { documentService } from "../../../services/DocumentService";
import { getController } from "../../../stores/controller/ControllerFactory";
import { copyPdfLink } from "../../../utils/copyPdfLink";
import { forceHttps } from "../../../utils/forceHttps";
import { formatDate } from "../../../utils/formatDate";
import { getSharingLinkOfHash } from "../../../utils/getSharingLinkOfHash";
import { isDefined } from "../../../utils/isDefined";
import { BuildingController } from "../BuildingController";
import { useConfig } from "../../../config/Config";

interface CardDocumentationData {
    documentation: IDocumentationRead;
}

interface CardDocumentationProps extends CardDocumentationData {
    onDelete: () => void;
    onEdit: () => void;
    disabled?: boolean;
    areaName: string | undefined;
    columnSizes: ColumnSizes;
}

const CardBuildingDocumentation: FunctionComponent<CardDocumentationProps> = (props: CardDocumentationProps) => {
    const { controller } = getController(BuildingController);
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const openPdfRef = useRef<HTMLDivElement>(null);
    const createLink = useRef<HTMLDivElement>(null);
    const { name, type, createdAt, id } = props.documentation;
    const imageUrl = props.documentation.user?.imageUrl ?? "";
    const firstName = props.documentation.user?.firstName ?? "";
    const lastName = props.documentation.user?.lastName ?? "";
    const docuTypeText = type === "assembly" ? "Montage" : "Wartung";
    const docuTypeIcon =
        type === "assembly" ? <IconHammer height={14} width={14} /> : <IconWrench height={14} width={14} />;
    const latestSharingLink = documentationSharingHashService.getNewestSharingHashOf(id, "documentation");
    const history = useHistory();
    const { url } = useRouteMatch();
    const { getFileSrc } = useConfig();
    const latestActiveSharingLink =
        isDefined(latestSharingLink) && new Date(latestSharingLink.expiresAt).getTime() > Date.now()
            ? getSharingLinkOfHash(latestSharingLink.hash)
            : undefined;
    const pdfUrl = getFileSrc(
        forceHttps(documentService.getLastDocumentByDocumentation(id, "documentation")?.imageUrl ?? "")
    );

    const onCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (openPdfRef.current === null) {
            // this should actually never happen, that user clicks faster than React can initialize openPdfRef...
            return;
        }
        // we have to check if user clicked on button (cardClick should than be ignored)
        // we have to do it like this, instead of Button onClick e.stopPropagation(), as the click event should proopagate in order
        // to close other Dropdowns which might be opened (window event listener)
        if (
            e.target === openPdfRef.current ||
            openPdfRef.current.contains(e.target as HTMLElement) ||
            e.target === hiddenFileInputRef.current ||
            hiddenFileInputRef.current?.contains(e.target as HTMLElement) ||
            e.target === createLink.current ||
            createLink.current?.contains(e.target as HTMLElement)
        ) {
            // don't call onClick, when user clicked on Dropdown Options Icon (to open Dropdown)
            return;
        }

        history.push(`${url}/documentations/${id}`);
    };

    return (
        <Card className="card uf-areaCard" onClick={onCardClick} disabled={props.disabled} isMenuIconFullHeight={true}>
            <GridColumns style={{ alignItems: "center", height: "100%" }} columnSizes={props.columnSizes}>
                <S.ColName>{name}</S.ColName>
                <div>
                    <S.DocuTypeIcon>{docuTypeIcon}</S.DocuTypeIcon>
                    <S.DocuTypeText>{docuTypeText}</S.DocuTypeText>
                </div>
                <div>{props.areaName}</div>
                <div>
                    <S.LastUpdate>
                        <IconUserCircle
                            width={29}
                            height={29}
                            firstname={firstName}
                            lastname={lastName}
                            picture={imageUrl}
                        />
                        {formatDate(createdAt)}
                    </S.LastUpdate>
                </div>
                {controller.waitingFor.loadDocumentationSharingHashes === true ? (
                    <p>loading</p>
                ) : isDefined(latestActiveSharingLink) ? (
                    <S.PdfUrl ref={hiddenFileInputRef}>
                        <S.CopyLinkWrapper className={"uf-pdfLink"}>
                            <S.Input type="text" value={latestActiveSharingLink} readOnly={true} />
                        </S.CopyLinkWrapper>
                        {isDefined(latestActiveSharingLink) && (
                            <S.CopyIconWrapper
                                className={"uf-copyLink"}
                                onClick={() => copyPdfLink(hiddenFileInputRef)}
                            >
                                <IconCopy width={19} height={19} />
                            </S.CopyIconWrapper>
                        )}
                    </S.PdfUrl>
                ) : (
                    <>
                        <S.CreateLink
                            className={"uf-generateLink"}
                            ref={createLink}
                            onClick={() =>
                                controller.createDocumentationSharingHash({
                                    type: "documentation",
                                    documentationId: id,
                                })
                            }
                        >
                            Link generieren
                        </S.CreateLink>
                    </>
                )}

                <S.IconDownloadWrapper ref={openPdfRef}>
                    <a href={pdfUrl} target="_blank" rel="noreferrer">
                        ansehen
                    </a>
                </S.IconDownloadWrapper>
            </GridColumns>
        </Card>
    );
};

export default observer(CardBuildingDocumentation);

//#region styles
const S = {
    Input: styled.input`
        text-decoration: none;
        outline: none;
        border: none;
        padding: 0;
        font-size: inherit;
        padding-right: ${unitize(20)};
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    `,
    Created: styled.div`
        align-items: center;
    `,
    LastUpdate: styled.div`
        display: flex;
        align-items: center;
        div {
            font-size: ${unitize(14)};
            margin-right: ${unitize(10)};
        }
    `,
    Type: styled.div`
        font-size: ${(props) => props.theme.typography.size.pBig}px;
    `,
    ColName: styled.div`
        font-weight: ${(props) => props.theme.typography.weight.bold};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    `,

    PdfUrl: styled.div`
        flex-direction: row-reverse;
        input {
            margin-left: ${unitize(5)};
            padding-right: ${unitize(20)};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: auto;
        }
        label {
            width: 100%;
        }
        svg {
            position: absolute;
        }
    `,
    CreateLink: styled.div`
        color: ${(props) => props.theme.color.primary.main};
    `,
    DocuTypeIcon: styled.div`
        display: flex;
        align-items: center;
        ${media("sm")} {
            display: none;
        }
    `,
    DocuTypeText: styled.div`
        display: none;
        ${media("sm")} {
            display: inline-block;
        }
    `,
    CopyIconWrapper: styled.div`
        cursor: pointer;
        height: ${unitize(24)};
        width: ${unitize(24)};
    `,
    CopyLinkWrapper: styled.div`
        float: right;
        width: calc(100% - ${unitize(24)});
    `,
    IconDownloadWrapper: styled.div`
        justify-content: flex-start;
        align-items: center;
        svg {
            g {
                rect {
                    width: ${unitize(40)};
                }
            }
        }
    `,
};
//#endregion styles

import { Button, unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { dataLayerService } from "../../services/DataLayerService";
import { session } from "../../session/Session";
import { layoutValues } from "../../templates/layoutValues";
import { useConfig } from "../../config/Config";
import { useTheme } from "@abs-safety/lock-book-web-ui/dist/esm/shared/designTheme";
import { isDefined } from "../../utils/isDefined";

interface ErrorPageTemplateProps {
    statusCode: number;
    headline: string;
    text?: string;
    /** first button is not changeable (back zu Doku), but you can define a second button */
    secondBtn?: {
        text: string;
        href: string;
        target?: string;
    };
}

const ErrorPageTemplate: FunctionComponent<ErrorPageTemplateProps> = (props: ErrorPageTemplateProps) => {
    const history = useHistory();
    const config = useConfig();
    const theme = useTheme();
    // # TODO: Rebranding - Remove after rebranding
    const serviceName = theme.oldLockBookBranding ? "Lock Book" : "ABS Digital";
    const productName = theme.oldLockBookBranding ? "Docu" : "Doku";

    useEffect(() => {
        dataLayerService.emitEvent("push", {
            category: dataLayerService.currentPage,
            action: "page_error",
            label: `${props.statusCode}`,
            payload: { ...props, location: window.location.href },
        });
    }, []);

    return (
        <S.Wrapper>
            <S.TextWrapper>
                <S.Headline>{props.statusCode}</S.Headline>
                <h2>{props.headline}</h2>
                <S.Divider />
                <p>
                    {isDefined(props.text)
                        ? props.text
                        : `Vielleicht möchtest Du zurück zu ${serviceName}. Wenn etwas kaputt ist, kannst Du auch ein Problem melden.`}
                </p>
                <S.ButtonWrapper>
                    <Button color="white" variant="outline">
                        <Link
                            to={`${config.docu.path_prefix}/`}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                history.push(`${config.docu.path_prefix}/`);
                                session.pageError = undefined;
                            }}
                        >
                            {`Zurück zu ${productName}`}
                        </Link>
                    </Button>
                    {props.secondBtn !== undefined ? (
                        <Button color="white" variant="outline">
                            <a href={props.secondBtn.href} target={props.secondBtn.target}>
                                {props.secondBtn.text}
                            </a>
                        </Button>
                    ) : (
                        <Button color="white" variant="outline">
                            <a href={`${config.docu.reportIssue_url}`} target={"_blank"} rel="noreferrer">
                                {"Problem melden"}
                            </a>
                        </Button>
                    )}
                </S.ButtonWrapper>
            </S.TextWrapper>
        </S.Wrapper>
    );
};
export default ErrorPageTemplate;

//#region styles
const S = {
    Wrapper: styled.div`
        min-height: calc(100vh - ${layoutValues.header.height}px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.color.primary.main};
    `,
    TextWrapper: styled.div`
        margin: 0 auto;
        max-width: ${unitize(450)};
        width: 100%;
        color: white;
    `,
    Headline: styled.h1`
        color: ${(props) => props.theme.color.white};
    `,
    ContentWrapper: styled.div`
        max-width: ${unitize(690)};
        width: 100%;
    `,
    Divider: styled.hr`
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        width: ${unitize(250)};
        margin: ${unitize(35)} 0;
    `,
    ButtonWrapper: styled.div`
        margin-top: ${unitize(35)};
        width: 100%;
        display: flex;
        justify-content: space-between;
    `,
};
//#endregion styles

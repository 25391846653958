import { ICurrentUser } from "../entities/User";
import { ApiResponse, ApiService } from "./ApiService";

export class AuthApi extends ApiService<ICurrentUser> {
    constructor() {
        super("", "hub");
    }

    async getDocumentedBeforeSurvey(): Promise<ApiResponse<boolean>> {
        return await this.get<boolean>(`/should-show-documented-before-survey`);
    }
}

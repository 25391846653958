import { ButtonClose, Modal } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface ModalExampleDeliveryNoteProps {
    onCloseClick: () => void;
    isOpen: boolean;
}

const ModalExampleDeliveryNote: FunctionComponent<ModalExampleDeliveryNoteProps> = (
    props: ModalExampleDeliveryNoteProps
) => {
    return (
        <Modal onCloseClick={props.onCloseClick} isOpen={props.isOpen} size="lg">
            <ButtonClose onClick={props.onCloseClick} className="uf-exampleNoteClose" />
            <S.ImageWrapper>
                <S.Image src={"/doku/example-deliveryNote.jpg"} />
            </S.ImageWrapper>
        </Modal>
    );
};

export default ModalExampleDeliveryNote;

//#region styles
const S = {
    ImageWrapper: styled.div`
        /* make sure nothing overflows. Especially when image is not found, the alt text ignores width */
        overflow: hidden;
    `,
    Image: styled.img`
        height: 100%;
        width: 100%;
        object-fit: cover;
    `,
    ButtonClose: styled(ButtonClose)`
        position: absolute;
        right: ${30 / 16}rem;
        top: ${30 / 16}rem;
    `,
};
//#endregion styles

import { Button, ButtonGroup, FormControl, Input, unitize } from "@abs-safety/lock-book-web-ui";
import { Form, FormikProps } from "formik";
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { AttachmentType, IBuildingAttachmentRead } from "../entities/BuildingAttachment";
import { session } from "../session/Session";
import { fileToUrlOrThumbnailElement } from "../utils/fileToUrlOrThumbnailElement";
import { gtmAutoSubmit } from "../utils/gtmEventCollection";
import { FilesProps, FormValues } from "./FileEditForm";
import FormAutoSubmit from "./FormAutoSubmit";
import FormGroup from "./FormGroup";
import FormGtmIntegration from "./FormGtmIntegration";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import PresignedImage from "./PresignedImage";
import { useConfig } from "../config/Config";

/** props: formikBag + file */
type FileEditFormFormProps = FormikProps<FormValues> & {
    file: IBuildingAttachmentRead;
    fileExtension: string;
} & FilesProps;

/**
 * Sub Component
 */
const FileEditFormForm: FunctionComponent<FileEditFormFormProps> = (props: FileEditFormFormProps) => {
    const { url } = useRouteMatch();
    const { getFileSrc } = useConfig();
    const history = useHistory();
    const { thumbnailElement, url: fileUrl } = fileToUrlOrThumbnailElement(props.file);

    // `refersTo` text depends which type the attachment has:
    const refersTo: string = ((type: AttachmentType): string => {
        switch (type) {
            case "building":
                return props.building?.name ?? "Objekt";
            case "area":
                return props.file.buildingAreaName ?? "Dachfläche";
            case "documentation":
                return props.file.documentationName ?? "Dokumentation";
            default:
                return "-";
        }
    })(props.file.type);

    const onDelete = () => {
        props.deleteFile(props.file.id);
        const url = history.location.pathname.split("/").slice(0, -2).join("/");
        history.push(url);
    };

    //set waitingForReloadUrl in order to use updated value if it changes
    const [waitingForReloadUrl, setWaitingForReloadUrl] = useState(props.waitingForReloadUrl);
    useEffect(() => {
        setWaitingForReloadUrl(props.waitingForReloadUrl);
    }, [props.waitingForReloadUrl]);

    return (
        <Form>
            <FormInput type="text" isHeading={true} name="imageFilename" label="Name" />
            {/* Only render FormSelect, as soon as options are loaded (LBWEBUI-321) */}
            {session.categories.length > 0 && (
                <>
                    <FormGroup child1Flex="0 0 50%">
                        <FormSelect
                            label="Typ"
                            name="categoryId"
                            options={session.categories.map((cat) => {
                                return {
                                    text: cat.category,
                                    value: cat.id.toString(),
                                };
                            })}
                        />
                        <FormControl>
                            <Input defaultValue={refersTo} readOnly label="Zugewiesen" type="text" />
                        </FormControl>
                    </FormGroup>
                    <FormGroup child1Flex="0 0 50%">
                        <FormControl>
                            <Input
                                defaultValue={moment(props.file.createdAt).format("L")}
                                readOnly
                                label="Hochgeladen am"
                                type="text"
                            />
                        </FormControl>
                        <FormControl>
                            <Input
                                defaultValue={`${props.file.creator.firstName} ${props.file.creator.lastName}`}
                                readOnly
                                label="Hochgeladen von"
                                type="text"
                            />
                        </FormControl>
                    </FormGroup>
                </>
            )}
            <S.ThumbnailFrame>
                {fileUrl && (
                    <PresignedImage
                        url={getFileSrc(props.file.imageUrl)}
                        reloadUrl={() => props.reloadUrl()}
                        waitingForValue={waitingForReloadUrl !== false ? waitingForReloadUrl : undefined}
                    />
                )}
                {!fileUrl && thumbnailElement}
            </S.ThumbnailFrame>

            <S.ButtonGroup>
                <ButtonGroup>
                    <Button>
                        <a
                            href={getFileSrc(props.file.imageDownloadUrl)}
                            download={props.values.imageFilename + props.fileExtension}
                        >
                            Herunterladen
                        </a>
                    </Button>
                    <Button color="decline" variant="outline">
                        <button type="button" onClick={onDelete}>
                            Löschen
                        </button>
                    </Button>
                </ButtonGroup>
            </S.ButtonGroup>
            <FormAutoSubmit onSuccess={gtmAutoSubmit("files")} />
            <FormGtmIntegration />
        </Form>
    );
};

export default observer(FileEditFormForm);

//#region styles
const S = {
    ButtonGroup: styled.div`
        margin-top: ${unitize(20)};
    `,
    ThumbnailFrame: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 320px;
        border-radius: ${(props) => props.theme.borderRadius};
        background-color: ${(props) => props.theme.color.lightestgrey};
    `,
};
//#endregion

import {
    Button,
    GridColumns,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    RadioButtonGroup,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { ICompanyRead } from "../../entities/Company";
import { companyService } from "../../services/CompanyService";
import { session } from "../../session/Session";
import { isNullish } from "../../utils/isNullish";
import FieldLoading from "../FieldLoading";
import FormGtmIntegration from "../FormGtmIntegration";
import FormInput from "../FormInput";
import { useConfig } from "../../config/Config";

type FormValues = {
    contactFirstName?: string | null;
    contactLastName?: string | null;
    phone1?: string;
    phone2?: string | null;
    email?: string;
    displayAssemblerInformation?: boolean;
};

interface ModalContactPersonPDFProps {
    onClose: () => void;
}

/** Outer Modal Component */
const ModalContactPersonPDF: FunctionComponent<ModalContactPersonPDFProps> = (props: ModalContactPersonPDFProps) => {
    const onSubmit = async (values: FormValues) => {
        if (isNullish(session.currentUser?.id)) return;
        await companyService.updateCompanyContactData(values);
        props.onClose();
    };
    return (
        <Modal isOpen size="lg" style={{ paddingTop: `${unitize(50)}` }} onCloseClick={props.onClose}>
            {session.currentUser === undefined ? (
                <FieldLoading text="Nutzerinformationen werden geladen..." />
            ) : (
                <Content
                    company={session.currentUser.company}
                    loading={companyService.waitingFor.update === true}
                    onSubmit={onSubmit}
                    onClose={props.onClose}
                />
            )}
        </Modal>
    );
};

export default observer(ModalContactPersonPDF);

type ContentProps = {
    company: ICompanyRead;
    loading: boolean;
    onSubmit: (values: FormValues) => void;
    onClose: () => void;
};
/** Inner Modal Component */
const Content: FunctionComponent<ContentProps> = (props: ContentProps) => {
    const [initialValues] = useState<FormValues>({
        contactFirstName: props.company.contactFirstName,
        contactLastName: props.company.contactLastName,
        email: props.company.email,
        phone1: props.company.phone1,
        phone2: props.company.phone2,
        displayAssemblerInformation: props.company.displayAssemblerInformation,
    });

    return (
        <Formik initialValues={initialValues} onSubmit={(values) => props.onSubmit(values)}>
            {(formikBag) => (
                <Form style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <ModalHeader title="PDF Einstellungen" />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* Left column */}
                        <div style={{ width: "50%", marginRight: "30px" }}>
                            <ModalContent>
                                <S.ModalContactPersonHeading>
                                    Ansprechpartner des Unternehmens
                                </S.ModalContactPersonHeading>
                                <S.ModalContentParagraph>
                                    Der Ansprechpartner wird in der PDF Dokumentation bei Deinen Unternehmensangaben
                                    ergänzt.
                                </S.ModalContentParagraph>
                                <S.ModalContentParagraph style={{ marginBottom: "87px" }}>
                                    Gib einen festen Ansprechpartner für alle Dokumentationen an oder nutze die
                                    Kontaktdaten des ausführenden Monteurs/Prüfers.
                                </S.ModalContentParagraph>
                                <S.ModalContentParagraphImage>
                                    Ausschnitt PDF Dokumentation
                                </S.ModalContentParagraphImage>
                                <S.ImageWrapper>
                                    <S.Image src={"/doku/example-pdf_contact.png"} />
                                </S.ImageWrapper>
                            </ModalContent>
                        </div>
                        {/* Right column */}
                        <ModalContent>
                            <S.ModalContactPersonHeading>
                                In der PDF als Kontaktdaten anzeigen:
                            </S.ModalContactPersonHeading>
                            <S.ModalContentParagraph>
                                <RadioButtonGroup
                                    options={displayAssemblerOptions}
                                    name="displayAssemblerInformation"
                                    value={booleanToString(formikBag.values.displayAssemblerInformation)}
                                    onChange={(e) => {
                                        const value = booleanStringToBoolean(e.target.value as "true" | "false");
                                        formikBag.setFieldValue("displayAssemblerInformation", value);
                                    }}
                                    regularWeight={true}
                                />
                                {/* dynamic form depending on selected radio button */}
                                {formikBag.values.displayAssemblerInformation === true ? null : <FormContactPerson />}
                            </S.ModalContentParagraph>
                        </ModalContent>
                    </div>
                    <ModalFooter>
                        <Button>
                            <button
                                className={"uf-savePDFSettings"}
                                disabled={formikBag.dirty === false || props.loading}
                            >
                                Speichern
                            </button>
                        </Button>
                        <Button variant="text" color="black">
                            <button type="button" onClick={props.onClose} disabled={props.loading}>
                                Abbrechen
                            </button>
                        </Button>
                    </ModalFooter>
                    <FormGtmIntegration />
                </Form>
            )}
        </Formik>
    );
};

/** Inner Component for dynamic fields */
const FormContactPerson: FunctionComponent = () => (
    <S.DynamicForm>
        <GridColumns columnSizes={{ xs: [1] }}>
            <div>
                <FormInput label="Vorname" name="contactFirstName" placeholder="Vorname" type="text" size="sm" />
            </div>
            <div>
                <FormInput label="Nachname" name="contactLastName" placeholder="Nachname" type="text" size="sm" />
            </div>
        </GridColumns>
        <GridColumns columnSizes={{ xs: [1] }}>
            <div>
                <FormInput label="Telefon" name="phone1" placeholder="Telefon" type="text" size="sm" />
            </div>
            <div>
                <FormInput label="Handy" name="phone2" placeholder="Handy" type="text" size="sm" />
            </div>
        </GridColumns>
        <FormInput label="E-Mail-Adresse" name="email" placeholder="E-Mail" type="text" size="lg" />
    </S.DynamicForm>
);

const displayAssemblerOptions: { value: string; label: string }[] = [
    { value: "true", label: "Ausführender Monteur/Prüfer" },
    { value: "false", label: "Festen Ansprechpartner" },
];

const booleanToString = (val: boolean | undefined): "true" | "false" => (val === true ? "true" : "false");
const booleanStringToBoolean = (val: "false" | "true"): boolean => (val === "true" ? true : false);

//#region styles
const S = {
    ModalContactPersonHeading: styled.h4`
        margin-top: ${unitize(0)};
    `,
    ModalContentParagraph: styled.p`
        margin-top: ${unitize(20)};
        color: black;
        width: 90%;
        font-size: ${unitize(15)};
        text-align: left;
    `,
    DynamicForm: styled.div`
        margin-top: ${unitize(30)};
        /* margin-left: ${unitize(25)}; */
    `,
    ImageWrapper: styled.div`
        /* make sure nothing overflows. Especially when image is not found, the alt text ignores width */
        margin-top: ${unitize(20)};
        margin-bottom: ${unitize(20)};
        /* overflow: hidden; */
        box-shadow: ${(props) => props.theme.shadow.card};
        max-height: 100%;
    `,
    ModalContentParagraphImage: styled.h5`
        margin-bottom: ${unitize(10)};
    `,
    Image: styled.img`
        max-height: 100%;
        max-width: 50%;
        object-fit: contain;
    `,
};
//#endregion styles

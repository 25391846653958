import { unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import FilesGrid from "../../../../components/FilesGrid";
import { attachmentService } from "../../../../services/AttachmentService";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { DocumentationController } from "../../DocumentationController";
import { DocumentController } from "../DocumentController";
import { useConfig } from "../../../../config/Config";

const Photos: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const documentationCompleted = controller.documentation?.isCompleted;

    useEffect(() => {
        documentController.loadDocumentationItemAttachments();
    }, [
        documentController.currentDocumentationItemIds,
        documentController.currentDocumentationItemIds.length,
        documentController,
    ]);

    return (
        <S.Component>
            <FilesGrid
                isLocked={documentationCompleted === true}
                insideSidemodal={true}
                files={documentController.documentationItemAttachments}
                waitingForLoadFiles={attachmentService.waitingFor.all !== false}
                waitingForUploadFile={attachmentService.waitingFor.upload}
                waitingForDeleteFile={attachmentService.waitingFor.delete}
                onUploadFiles={documentController.uploadFiles.bind(documentController)}
                onDeleteFile={documentController.deleteFile.bind(documentController)}
                onUpdateFile={documentController.updateFile.bind(documentController)}
                loadFiles={documentController.loadDocumentationItemAttachments.bind(documentController)}
            />
        </S.Component>
    );
};

export default observer(Photos);

//#region styles
const S = {
    Component: styled.div`
        margin: ${unitize(20)} 0;
    `,
};
//#endregion styles

import { Button, ButtonGroup, MainNavigation, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { IDocumentationItemRead } from "../../../../entities/DocumentationItem";
import { IDocumentationItemAnswerWrite } from "../../../../entities/DocumentationItemAnswer";
import { IDocumentationItemQuestionWithAnswer } from "../../../../entities/DocumentationItemQuestion";
import { dataLayerService } from "../../../../services/DataLayerService";
import { installedArticleService } from "../../../../services/InstalledArticleService";
import { layoutService } from "../../../../services/LayoutService";
import { getController } from "../../../../stores/controller/ControllerFactory";
import { docuItemIsLocked } from "../../../../utils/docuItemIsLocked";
import { getLocalStorageValue, setLocalStorageValue } from "../../../../utils/localStorage";
import { DocumentationController } from "../../DocumentationController";
import PopupAfterLockMultiple from "../components/PopupAfterLockMultiple";
import PopupUnansweredMandatoryQuestions from "../components/PopupUnansweredMandatoryQuestions";
import PopupWarnMultipleSelection from "../components/PopupWarnMultipleSelection";
import { DocumentController, SubViewKey } from "../DocumentController";
import { isLockReasonRowVisible } from "./components/isLockReasonRowVisible";
import ItemDetails from "./ItemDetails";
import ModalLockReason from "./ModalLockReason";
import Photos from "./Photos";
import Questions from "./Questions";

interface ColDetailsProps {
    accordionBreakPoint: number;
}

const ColDetails: FunctionComponent<ColDetailsProps> = (props: ColDetailsProps) => {
    const { controller } = getController(DocumentationController);
    const { controller: documentController } = getController(DocumentController);
    const [showPopup, setShowPopup] = useState<null | JSX.Element>(null);
    const neverShowPopupWarnMultipleSelection = getLocalStorageValue("neverShowPopupWarnMultipleSelectionInDocument");
    const neverShowPopupAfterLockMultiple = getLocalStorageValue("neverShowPopupAfterLockMultipleInDocument");
    const [isModalLockReasonVisible, setIsModalLockReasonVisible] = useState(false);
    const whiteSpaceRegEx = /^ *$/;
    const windowWidth = layoutService.windowWidth;
    const documentationCompleted = controller.documentation?.isCompleted;

    // if other docuItems get selected...
    useEffect(() => {
        if (documentController.currentDocumentationItemIds.length > 1) {
            if (neverShowPopupWarnMultipleSelection !== true) {
                setShowPopup(
                    <PopupWarnMultipleSelection
                        onClose={(showNeverAgain) => {
                            setShowPopup(null);
                            if (showNeverAgain) {
                                setLocalStorageValue("neverShowPopupWarnMultipleSelectionInDocument", true);
                            }
                        }}
                    />
                );
            }
            return;
        }
        setShowPopup(null);
    }, []);

    const lockOrReleaseDocuItems = useCallback(
        async (method: "lock" | "release", lockReason?: string) => {
            const allQuestionsOfSelection = getAllQuestionsOfSelection();

            if (!validateAnswers(allQuestionsOfSelection)) {
                return;
            }

            const answersToSave: IDocumentationItemAnswerWrite["answers"] = allQuestionsOfSelection.map((question) => ({
                answer: question.answer?.toString() ?? "",
                questionIds: [question.id],
            }));

            await documentController.answerQuestions(answersToSave);
            documentController
                .updateDocumentationItems(documentController.currentDocumentationItemIds, {
                    isSuccessful: method === "release",
                    questionsAnswered: true,
                    lockReason,
                })
                .then(() => {
                    documentController.currentDocumentationItemIds.length > 0 &&
                        dataLayerService.emitClick({
                            action: method === "release" ? "release_docuitem" : "lock_docuitem",
                            category: "document",
                            label: documentController.currentDocumentationItemIds.length === 1 ? "single" : "multiple",
                            payload: method === "release" ? undefined : lockReason,
                        });
                });
        },
        [documentController.currentDocumentationItemIds]
    );

    /**
     * validate answers (mandatory questions etc.) and show validation errors to user
     * @returns allAreValid (bool)
     */
    const validateAnswers = (questions: IDocumentationItemQuestionWithAnswer[]) => {
        const unansweredMandatoryQuestions = questions.filter((question) => {
            return (
                (question.isMandatory && (question.answer === null || question.answer === "")) ||
                (question.answer !== undefined && question.answer !== null && question.isMandatory
                    ? whiteSpaceRegEx.test(question.answer)
                    : undefined)
            );
        });

        if (unansweredMandatoryQuestions.length > 0) {
            setShowPopup(<PopupUnansweredMandatoryQuestions onClose={() => setShowPopup(null)} />);
            documentController.setDocumentationItemQuestionsWithErrors(unansweredMandatoryQuestions.map((q) => q.id));
            return false;
        }
        return true;
    };

    const onBtnReleaseClick = useCallback(() => {
        lockOrReleaseDocuItems("release");
    }, []);

    const onBtnLockClick = () => {
        const allQuestionsOfSelection = getAllQuestionsOfSelection();
        if (!validateAnswers(allQuestionsOfSelection)) {
            return;
        }

        const allItemsAreLocked =
            documentController.documentationItems.find((docuItem) => !docuItemIsLocked(docuItem)) === undefined;
        if (allItemsAreLocked) {
            return;
        }

        if (documentController.currentDocumentationItemIds.length > 1) {
            if (neverShowPopupAfterLockMultiple !== true) {
                setShowPopup(
                    <PopupAfterLockMultiple
                        onClose={(showNeverAgain) => {
                            setShowPopup(null);
                            if (showNeverAgain) {
                                setLocalStorageValue("neverShowPopupAfterLockMultipleInDocument", true);
                            }
                        }}
                    />
                );
            }
            lockOrReleaseDocuItems("lock");
            return;
        }

        if (isLockReasonRowVisible(documentController.documentationItems)) {
            lockOrReleaseDocuItems("lock");
        } else {
            setIsModalLockReasonVisible(true);
        }
    };

    const submitLockReason = (reason: string) => {
        setIsModalLockReasonVisible(false);
        lockOrReleaseDocuItems("lock", reason);
    };

    const submitLockWithoutReason = () => {
        setIsModalLockReasonVisible(false);
        lockOrReleaseDocuItems("lock", "");
    };

    const onItemClick = (subViewKey: SubViewKey) => {
        documentController.setSubView(subViewKey);
    };

    const renderSubView = () => {
        const selectedInstalledArticle: IDocumentationItemRead | undefined = documentController.documentationItems.find(
            (item) => item.id === documentController.currentDocumentationItemIds[0]
        );

        const installedArticle = installedArticleService.get(selectedInstalledArticle?.installedArticleId);

        switch (documentController.subView) {
            case SubViewKey.ItemDetails:
                if (installedArticle !== undefined) {
                    return <ItemDetails selectedInstalledArticle={installedArticle} />;
                }
                break;
            case SubViewKey.Questions:
                return <Questions />;
            case SubViewKey.Photos:
                return <Photos />;
        }
    };

    const btnAmountSuffix =
        documentController.currentDocumentationItemIds.length > 1
            ? ` (${documentController.currentDocumentationItemIds.length})`
            : "";

    const isAssembly = controller.documentation?.type === "assembly";

    return (
        <S.Container>
            {showPopup !== null && <S.PopupWrapper>{showPopup}</S.PopupWrapper>}
            {isModalLockReasonVisible && (
                <ModalLockReason onCloseClick={submitLockWithoutReason} onSubmit={submitLockReason} />
            )}
            <S.ButtonWrapper className="uf-releaseOrLock">
                {documentationCompleted !== true && (
                    <ButtonGroup>
                        <Button
                            color="success"
                            disabled={documentController.waitingFor.updateDocumentationItems !== false}
                        >
                            <button className="uf-release" type="button" onClick={onBtnReleaseClick}>
                                Freigeben{btnAmountSuffix}
                            </button>
                        </Button>
                        <Button
                            color="decline"
                            disabled={documentController.waitingFor.updateDocumentationItems !== false}
                        >
                            <button type="button" className="uf-lock" onClick={onBtnLockClick}>
                                Sperren{btnAmountSuffix}
                            </button>
                        </Button>
                    </ButtonGroup>
                )}
            </S.ButtonWrapper>
            <S.Main>
                <S.Navigation>
                    <MainNavigation
                        key={documentController.currentDocumentationItemIds.length}
                        onActiveChange={(id) => onItemClick(id as SubViewKey)}
                        activeId={documentController.subView}
                        small={windowWidth < props.accordionBreakPoint}
                        items={[
                            {
                                id: SubViewKey.ItemDetails,
                                text: "Artikeldetails",
                                disabled: documentController.currentDocumentationItemIds.length > 1,
                            },
                            {
                                id: SubViewKey.Questions,
                                text: "Fragenkatalog",
                            },
                            {
                                id: SubViewKey.Photos,
                                text: isAssembly ? "Montagefotos" : "Wartungsfotos",
                                disabled: documentController.currentDocumentationItemIds.length > 1,
                            },
                        ]}
                    />
                </S.Navigation>

                <S.Content>{renderSubView()}</S.Content>
            </S.Main>
        </S.Container>
    );
};

export default observer(ColDetails);

const getAllQuestionsOfSelection = () => {
    const { controller: documentController } = getController(DocumentController);
    return documentController.documentationItemQuestions.filter(
        (question) => documentController.currentDocumentationItemIds.indexOf(question.documentationItemId) >= 0
    );
};

//#region styles
const S = {
    Container: styled.div`
        position: relative;
        height: 100%;
        overflow-y: auto;
        padding: 0 ${unitize(30)} 0 ${unitize(15)};
        display: flex;
        flex-direction: column;
        .accordion & {
            flex-direction: column-reverse;
            justify-content: space-between;
            padding: 0;
            /* move hole view up. This is more a hack, as WEBUI's SideModal gives view too much padding, and we need space... */
            margin-top: -${unitize(20)};
        }
    `,

    ButtonWrapper: styled.div`
        /* height header other cols: 136; but MainNavigation's text is ${unitize(18)} lower compared to h3 */
        height: ${136 - 18}px;
        padding-top: ${unitize(30)};
        .accordion & {
            padding-top: ${unitize(10)};
            height: unset;
        }
    `,
    Navigation: styled.div`
        > div {
            width: 100%;
        }
    `,
    PopupWrapper: styled.div`
        position: absolute;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: ${unitize(200)};
    `,
    Main: styled.div`
        flex-shrink: 1;
        flex-basis: auto;
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
    `,
    Content: styled.div`
        /*
        TODO: do we want that only content scrolls? than we need to limit height as well,
        and we run into problem, that Dropdowns are cut (absolute positioned)
        */
        // overflow-y: auto;
    `,
};
//#endregion styles

import {
    Card,
    ColumnSizes,
    GridColumns,
    IconCheck,
    IconEdit,
    IconHammer,
    IconTrash,
    IconUserCircle,
    IconWrench,
    ProgressBar,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled, { useTheme } from "styled-components";
import { IDocumentationRead } from "../../../entities/Documentation";
import { formatDate } from "../../../utils/formatDate";
import { isDefined } from "../../../utils/isDefined";
import { isNotEmptyString } from "../../../utils/isNotEmptyString";

interface CardDocumentationData {
    documentation: IDocumentationRead;
    columnSizes: ColumnSizes;
}

interface CardDocumentationProps extends CardDocumentationData {
    onCardClick: () => void;
    onDelete: () => void;
    onEdit: () => void;
    disabled?: boolean;
    type: "maintenance" | "assembly";
}

const CardDocumentation: FunctionComponent<CardDocumentationProps> = (props: CardDocumentationProps) => {
    const theme = useTheme();
    const firstName = props.documentation.user?.firstName ?? "unbekannt";
    const lastName = props.documentation.user?.lastName ?? "";
    const amountDocumented = props.documentation.amountDocumented > 0 ? props.documentation.amountDocumented : 0;
    return (
        <S.Component>
            <Card
                className="card uf-cardDocumentation"
                onClick={props.onCardClick}
                disabled={props.disabled}
                dropdownItems={[
                    {
                        icon: <IconEdit />,
                        text: "Bearbeiten",
                        onClick: props.onEdit,
                    },
                    {
                        icon: <IconTrash />,
                        text: "Löschen",
                        textColor: theme.color.error,
                        onClick: props.onDelete,
                    },
                ]}
            >
                <S.Icon>
                    {props.type === "maintenance" ? (
                        <IconWrench width={19.6} height={15} />
                    ) : (
                        <IconHammer width={19.6} height={15} />
                    )}
                </S.Icon>
                <GridColumns
                    columnSizes={props.columnSizes}
                    childrensDisplay="flex"
                    style={{ height: "100%", alignItems: "center" }}
                >
                    <S.Name className="overflow-hidden">
                        <h4 className="truncate"> {props.documentation.name}</h4>
                        <p className="truncate d-xs-none d-lg-block">
                            Angelegt von:
                            {` ${firstName} ${lastName}`}
                        </p>
                    </S.Name>

                    <S.Date>{formatDate(props.documentation.createdAt)}</S.Date>
                    <S.LastUpdated className="overflow-hidden">
                        {isDefined(props.documentation.lastUpdatedBy) &&
                        isNotEmptyString(props.documentation.lastUpdatedBy.firstName) &&
                        isNotEmptyString(props.documentation.lastUpdatedBy.lastName) ? (
                            <>
                                <S.UserIcon>
                                    <IconUserCircle
                                        firstname={props.documentation.lastUpdatedBy.firstName}
                                        lastname={props.documentation.lastUpdatedBy.lastName}
                                    />
                                </S.UserIcon>
                                <S.LastUpdatedData className="truncate">
                                    <p>{formatDate(props.documentation.updatedAt)}</p>
                                    <p className="truncate">
                                        von{" "}
                                        {props.documentation.lastUpdatedBy.firstName +
                                            " " +
                                            props.documentation.lastUpdatedBy.lastName}
                                    </p>
                                </S.LastUpdatedData>
                            </>
                        ) : (
                            <S.Date>{formatDate(props.documentation.updatedAt)}</S.Date>
                        )}
                    </S.LastUpdated>
                    <S.ProgressColumn>
                        <S.Progress className={props.documentation.isCompleted ? "d-xs-none d-md-flex" : ""}>
                            <ProgressBar
                                count={amountDocumented}
                                // currently in Old API the documentationItemIds are always defined BESIDES in response of POST /documentations
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                totalCount={props.documentation.documentationItemIds?.length ?? 0}
                                text="Artikel dokumentiert"
                            />
                        </S.Progress>
                        <S.Completed>
                            {props.documentation.isCompleted && (
                                <>
                                    <p>Abgeschlossen</p>
                                    <S.CompletedIcon>
                                        <IconCheck color="currentColor" width={19.6} height={15} />
                                    </S.CompletedIcon>{" "}
                                </>
                            )}
                        </S.Completed>
                    </S.ProgressColumn>
                </GridColumns>
            </Card>
        </S.Component>
    );
};

export default observer(CardDocumentation);

//#region styles
const S = {
    Component: styled.div`
        .card {
            height: ${unitize(110)};
        }
    `,
    Menu: styled.div`
        position: absolute;
        right: ${unitize(10)};
        top: ${unitize(2)};
    `,
    Icon: styled.div`
        position: absolute;
        left: ${unitize(10)};
        top: ${unitize(10)};
    `,
    LastUpdated: styled.div`
        display: flex;
        align-items: center;
    `,
    UserIcon: styled.div`
        margin-right: ${unitize(10)};
    `,
    LastUpdatedData: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Name: styled.div`
        display: flex;
        flex-direction: column;
        margin-left: ${unitize(20)};
        justify-content: center;
        /* this column is not fully in the vertical center, to keep the distance from the icon */
        padding-top: ${unitize(4)};
        p {
            font-size: ${(props) => props.theme.typography.size.h5}rem;
        }
    `,
    Date: styled.div`
        font-size: ${(props) => props.theme.typography.size.pBig}rem;
        display: flex;
        align-items: center;
    `,
    ProgressColumn: styled.div`
        display: flex;
        column-gap: ${unitize(20)};
    `,
    Progress: styled.div`
        align-items: center;
        line-height: 0;
    `,
    Completed: styled.div`
        color: ${(props) => props.theme.color.success.main};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: ${unitize(20)};
    `,
    CompletedIcon: styled.div`
        margin-left: ${unitize(10)};
    `,
};
//#endregion styles

import { GlobalStyle, GlobalStyleResponsiveScale } from "@abs-safety/lock-book-web-ui";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import GtmIntegration from "./components/GtmIntegration";
import Header from "./components/Header";
import Toasts from "./components/Toasts";
import GlobalStyleApp from "./GlobalStyleApp";
import Admin from "./pages/Admin/Admin";
import Building from "./pages/Building/Building";
import BuildingArea from "./pages/BuildingArea/BuildingArea";
import BuildingList from "./pages/BuildingList/BuildingList";
import Documentation from "./pages/Documentation/Documentation";
import ModalPreDocuSurvey from "./pages/Documentation/subviews/ModalPreDocuSurvey";
import Error404 from "./pages/errors/Error404";
import Error500 from "./pages/errors/Error500";
import { session } from "./session/Session";
import { isDefined } from "./utils/isDefined";
import { useConfig } from "./config/Config";

const history = createBrowserHistory();

const App: FunctionComponent = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(true);
    const config = useConfig();

    useEffect(() => {
        session.applyLocale();
        session.loadConstraints();
        session.loadBuildingAttachmentCategories();
        session.loadCurrentUser();
    }, []);

    useEffect(() => {
        setHtmlDocumentTitle();
    }, []);

    return (
        <ThemeProvider theme={config.frameworks.webui}>
            <S.App id="app">
                <Toasts />
                <GlobalStyle />
                <GlobalStyleResponsiveScale />
                <GlobalStyleApp />
                <Sentry.ErrorBoundary fallback={<Error500 />}>
                    <Router history={history}>
                        <GtmIntegration
                            gtmKeys={[process.env.REACT_APP_GTM, process.env.REACT_APP_GTM_GA4].filter(isDefined)}
                        />
                        <Header />
                        <Switch>
                            {/*
                                Order matters! Go from more specific (more URL segments to less URL segments)
                                Otherwise (if not `exact`), a Route matches before the more specific Route would match
                                */}

                            {/* Protected */}
                            <Route
                                path={`${config.docu.path_prefix}/buildings/:buildingId/areas/:buildingAreaId/documentations/:documentationId`}
                                component={Documentation}
                            />
                            <Route
                                path={`${config.docu.path_prefix}/buildings/:buildingId/areas/:buildingAreaId`}
                                component={BuildingArea}
                            />
                            <Route path={`${config.docu.path_prefix}/buildings/:buildingId`} component={Building} />
                            <Route exact path={`${config.docu.path_prefix}/buildings`} component={BuildingList} />

                            {/* Main */}
                            <Route exact path={`${config.docu.path_prefix}/`}>
                                <Redirect to={`${config.docu.path_prefix}/buildings`} />
                            </Route>
                            <Route path={`${config.docu.path_prefix}/admin`} component={Admin} />
                            <Route path={`${config.docu.path_prefix}/login`}>
                                <Redirect to={`${config.docu.path_prefix}/buildings`} />
                            </Route>
                            {/* 404 */}
                            <Route path="*" component={Error404} />
                        </Switch>
                    </Router>
                    {modalOpen && session.shouldShowSurvey && (
                        <ModalPreDocuSurvey onClose={() => setModalOpen(false)} />
                    )}
                </Sentry.ErrorBoundary>
            </S.App>
        </ThemeProvider>
    );
};

function setHtmlDocumentTitle() {
    const env = process.env.REACT_APP_ENVIRONMENT;
    switch (env) {
        case "local":
            document.title = "Local";
            break;
        case "dev":
            document.title = "dev";
            break;
        case "staging":
            document.title = "staging";
            break;
        default:
            // TODO remove this after we got rid of the lock-book.com domain
            if (location.hostname.includes("lock-book.com")) {
                document.title = "Docu"; // override "Doku"
            }
        // leave title for prod how it is in index.html
    }
}

export default observer(App);

//#region styles
const S = {
    App: styled.div`
        min-height: 100vh;
        background-color: ${(props) => props.theme.color.lightestgrey};
        display: block;
    `,
};
//#endregion styles

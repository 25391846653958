import { reaction } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { localConfig } from "../../../../../config/localConfig";
import { documentationItemService } from "../../../../../services/DocumentationItemService";
import { getController } from "../../../../../stores/controller/ControllerFactory";
import { useDebounce } from "../../../../../utils/useDebounce";
import { DocumentationController } from "../../../DocumentationController";
import { DocumentController } from "../../DocumentController";
import { AnswerInputStyle } from "./AnswerInput";
import QuestionAnswerContainer from "./QuestionAnswerContainer";

const LockReasonRow: FunctionComponent = () => {
    const { controller: documentController } = getController(DocumentController);
    const { controller } = getController(DocumentationController);
    const documentationCompleted = controller.documentation?.isCompleted;
    const [value, setValue] = useState<null | string>(null);
    const [hasDifferentValues, setHasDifferentValues] = useState(false);

    useEffect(() => {
        // update form states when different docuItems get selected
        return reaction(
            () => documentController.currentDocumentationItemIds,
            (selectedDocuItemIds) => {
                const selectedDocuItems = documentationItemService.getMultiple(selectedDocuItemIds);
                const lockReasonsOfSelected = selectedDocuItems.map((docuItem) => docuItem.lockReason);
                const { value: initialValue, hasDifferentValues } = getLockReasonInputValue(lockReasonsOfSelected);
                setValue(initialValue);
                setHasDifferentValues(hasDifferentValues);
            },
            { fireImmediately: true }
        );
    }, []);

    const submit = useDebounce((lockReason: string) => {
        documentController.updateDocumentationItems(documentController.currentDocumentationItemIds, {
            lockReason,
        });
    }, localConfig.DEBOUNCE_WAIT_DOCUMENT_QUESTIONS);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lockReason = e.target.value;
        setValue(lockReason);
        submit(lockReason);
    };

    return (
        <QuestionAnswerContainer
            question="Sperrgrund"
            answer={
                <AnswerInputStyle
                    type="text"
                    disabled={value === null || documentationCompleted === true}
                    value={value ?? ""}
                    placeholder={hasDifferentValues ? "???" : "bitte eingeben"}
                    onChange={onInputChange}
                />
            }
        />
    );
};

export default observer(LockReasonRow);

/** multiple docuItems can be selected -> what to show in input value? */
export const getLockReasonInputValue: (lockReasonsOfMultipleDocuItems: (string | null | undefined)[]) => {
    value: string;
    hasDifferentValues: boolean;
} = (lockReasonsOfMultipleDocuItems) => {
    if (lockReasonsOfMultipleDocuItems.length === 1) {
        return { value: lockReasonsOfMultipleDocuItems[0] ?? "", hasDifferentValues: false };
    }
    // if multiple docuItems are selected
    const value = lockReasonsOfMultipleDocuItems[0];
    let hasDifferentValues = false;
    for (const reason of lockReasonsOfMultipleDocuItems) {
        if (reason !== value) {
            hasDifferentValues = true;
        }
    }

    if (hasDifferentValues) {
        return {
            value: "",
            hasDifferentValues,
        };
    }
    return {
        value: value ?? "",
        hasDifferentValues: false,
    };
};

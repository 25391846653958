import {
    Button,
    Checkbox,
    FormControl,
    IconTrash,
    Input,
    RadioButtonGroup,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import FormInput from "../../../../components/FormInput";
import FormSelect from "../../../../components/FormSelect";
import { localConfig } from "../../../../config/localConfig";
import {
    allQuestionTypes,
    IArticleQuestionWrite,
    QuestionId,
    QuestionType,
} from "../../../../entities/ArticleQuestion";
import { DocumentationType } from "../../../../entities/Documentation";
import { getQuestionTypeName } from "../../../../utils/getQuestionTypeName";
import { isDefined } from "../../../../utils/isDefined";
import { yesNoAnswerValueToText } from "../../../Documentation/documentView/subviews/components/AnswerYesNo";
import { Label } from "./Label";

interface QuestionFormProps {
    values?: FormValues;
    onChange: (values: FormValues) => void;
}

export type FormValues = Pick<
    IArticleQuestionWrite,
    "question" | "documentationType" | "isMandatory" | "defaultAnswer" | "min" | "max" | "items"
> & {
    id: QuestionId;
    questionType?: QuestionType;
    invalid?: boolean;
    /** marks a question, which is not yet created on API (temp question) */
    isNew?: boolean;
};

const QuestionForm: FunctionComponent<QuestionFormProps> = (props: QuestionFormProps) => {
    if (props.values === undefined) {
        return <S.NoQuestionSelected>keine Frage ausgewählt</S.NoQuestionSelected>;
    }

    const validationSchema = Yup.object().shape<Partial<FormValues>>({
        question: Yup.string()
            .required(localConfig.VALIDATION_ERROR_MSG_REQUIRED)
            .max(100, localConfig.VALIDATION_ERROR_MSG_MAX_LENGTH),
        defaultAnswer: Yup.string().max(100, localConfig.VALIDATION_ERROR_MSG_MAX_LENGTH),
    });

    const resetQuestionTypeSpecificFields = (formikBag: FormikProps<FormValues>, newQuestionType: QuestionType) => {
        formikBag.setFieldValue("defaultAnswer", undefined);
        formikBag.setFieldValue("min", undefined);
        formikBag.setFieldValue("max", undefined);
        formikBag.setFieldValue("items", undefined);
        if (newQuestionType === "yesno") {
            formikBag.setFieldValue("defaultAnswer", "2"); // set default to 2 => "keine Angabe"
        }
    };

    const addAnswerToList = (formikBag: FormikProps<FormValues>) => {
        formikBag.setFieldValue("items", [...(formikBag.values.items ?? []), ""]);
    };

    const onRemoveListAnswer = (formikBag: FormikProps<FormValues>, index: number) => {
        const items = formikBag.values.items;
        if (!isDefined(items)) {
            return;
        }
        formikBag.setFieldValue("items", [...items.slice(undefined, index), ...items.slice(index + 1)]);
    };

    return (
        <Formik initialValues={props.values} validationSchema={validationSchema} onSubmit={props.onChange}>
            {(formikBag) => (
                <Form autoComplete="off">
                    <FormikOnChangeObserver onChange={props.onChange} />
                    <Label bold>Verwendung für</Label>
                    <S.RadioButtons>
                        <RadioButtonGroup
                            options={documentationTypeOptions}
                            name="documentationType"
                            value={formikBag.values.documentationType}
                            onChange={formikBag.handleChange}
                            regularWeight={true}
                        />
                    </S.RadioButtons>
                    <FormInput label="Frage" name="question" type="text" />
                    <FormControl>
                        <Checkbox
                            text="Pflichtfrage"
                            name="isMandatory"
                            onChange={(checked) => formikBag.setFieldValue("isMandatory", checked)}
                            checked={formikBag.values.isMandatory ?? false}
                        />
                    </FormControl>
                    <FormSelect
                        label="Antworttyp"
                        name="questionType"
                        options={questionTypeOptions}
                        onChangeHook={(newQuestionType) =>
                            resetQuestionTypeSpecificFields(formikBag, newQuestionType as QuestionType)
                        }
                    />

                    {formikBag.values.questionType === "text" && (
                        <FormInput label="Standardantwort" name="defaultAnswer" isOptional type="text" />
                    )}
                    {formikBag.values.questionType === "number" && (
                        <>
                            <FormInput label="Standard Wert" name="defaultAnswer" isOptional type="number" />
                            <FormInput label="Minimaler Wert" name="min" isOptional type="number" />
                            <FormInput label="Maximaler Wert" name="max" isOptional type="number" />
                        </>
                    )}
                    {formikBag.values.questionType === "yesno" && <FormPartYesNo formikBag={formikBag} />}
                    {formikBag.values.questionType === "list" && (
                        <FormPartList
                            formikBag={formikBag}
                            addAnswerToList={addAnswerToList}
                            onRemoveListAnswer={onRemoveListAnswer}
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default QuestionForm;

/** part of form if questionType=yes_no selected */
const FormPartYesNo = (props: { formikBag: FormikProps<FormValues> }) => (
    <FormControl>
        <Label bold>Standardantwort</Label>
        <RadioButtonGroup
            options={yesNoAnswerOptions}
            name="defaultAnswer"
            value={props.formikBag.values.defaultAnswer}
            onChange={props.formikBag.handleChange}
            regularWeight={true}
        />
    </FormControl>
);

/** part of form if questionType=list selected */
const FormPartList = (props: {
    formikBag: FormikProps<FormValues>;
    addAnswerToList: (formikBag: FormikProps<FormValues>) => void;
    onRemoveListAnswer: (formikBag: FormikProps<FormValues>, index: number) => void;
}) => (
    <div>
        <Label bold>Antworten</Label>
        {props.formikBag.values.items?.map((value, index) => (
            <FormControl key={index} size="sm">
                <S.ListAnswerRow>
                    <Input
                        type="text"
                        name={`items.${index}`}
                        value={value}
                        onChange={props.formikBag.handleChange}
                        placeholder="Antwort eingeben"
                    />
                    <S.ListAnswerDeleteBtn
                        type="button"
                        onClick={() => props.onRemoveListAnswer(props.formikBag, index)}
                    >
                        <IconTrash height={20} width={20} color="current" />
                    </S.ListAnswerDeleteBtn>
                </S.ListAnswerRow>
            </FormControl>
        ))}
        <div style={{ marginBottom: unitize(20) }}>
            <Button variant="outline" size="small">
                <button type="button" onClick={() => props.addAnswerToList(props.formikBag)}>
                    + Antwortmöglichkeit anlegen
                </button>
            </Button>
        </div>
        <FormSelect
            label="Standardantwort"
            name="defaultAnswer"
            placeholder="keine Standardantwort"
            options={(props.formikBag.values.items ?? []).map((value) => ({ text: value, value }))}
        ></FormSelect>
    </div>
);

const yesNoAnswerOptions: { value: string; label: string }[] = [
    { value: "1", label: yesNoAnswerValueToText[1] },
    { value: "0", label: yesNoAnswerValueToText[0] },
    { value: "2", label: yesNoAnswerValueToText[2] },
];

/**
 * As Formik doesn't have an `onChange` event, we need this Obeserver component.
 * See: https://plainenglish.io/blog/how-to-listen-to-formik-onchange-event-in-react
 * or: https://github.com/jaredpalmer/formik/issues/271
 */
const FormikOnChangeObserver: FunctionComponent<Pick<QuestionFormProps, "onChange">> = (props) => {
    const { values } = useFormikContext<FormValues>();
    useEffect(() => {
        props.onChange(values);
        // if props is in dependencies as ESLint wiches, the effect gets called each time twice
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    return null;
};

const documentationTypeOptions: { value: DocumentationType; label: string }[] = [
    { value: "assembly", label: "Montage" },
    { value: "maintenance", label: "Wartung" },
];

const questionTypeOptions = allQuestionTypes.map((type) => ({ value: type, text: getQuestionTypeName(type) }));

//#region styles
const S = {
    NoQuestionSelected: styled.div`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        color: ${(props) => props.theme.color.darkgrey};
    `,
    RadioButtons: styled.div`
        margin-bottom: ${unitize(20)};
        display: flex;
        gap: ${unitize(30)};
    `,
    ListAnswerRow: styled.div`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 40px;
    `,
    ListAnswerDeleteBtn: styled.button`
        background-color: transparent;
        border-radius: 50%;
        height: ${unitize(34)};
        width: ${unitize(34)};
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        :hover {
            background-color: ${(props) => props.theme.color.warning};
            fill: white;
        }
    `,
};
//#endregion styles

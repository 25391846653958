import { Checkbox, media, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { getController } from "../../../stores/controller/ControllerFactory";
import { getInstalledArticlePreviewName } from "../../../utils/getInstalledArticlePreviewName";
import { AddArticleController } from "../AddArticleController";

interface CardInstalledArticleProps {
    installedArticle: IArticleOverviewItem["installedArticles"][0];
    availableToAdd: boolean;
    whenSelectChanges: (selected: boolean) => void;
}

const CardInstalledArticle: FunctionComponent<CardInstalledArticleProps> = (props: CardInstalledArticleProps) => {
    const { controller: modalController } = getController(AddArticleController);
    return (
        <S.CardInstalledArticle availableToAdd={props.availableToAdd}>
            <S.ContentWrapper>
                {props.availableToAdd && (
                    <S.CheckboxWrapper>
                        <Checkbox
                            checked={modalController.availableInstalledArticlesSelected.has(props.installedArticle.id)}
                            onChange={props.whenSelectChanges}
                        />
                    </S.CheckboxWrapper>
                )}
                <p>{getInstalledArticlePreviewName(props.installedArticle)} </p>
            </S.ContentWrapper>
            {props.availableToAdd ? (
                <S.Status>
                    Status: <span>Nicht in Dokumentation</span>
                </S.Status>
            ) : (
                <S.Status>Status: Bereits in Dokumentation</S.Status>
            )}
        </S.CardInstalledArticle>
    );
};

export default observer(CardInstalledArticle);

// Styled Components
const S = {
    CardInstalledArticle: styled.div<{ availableToAdd: boolean }>`
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        height: ${unitize(45)};
        background-color: ${(props) => props.theme.color.lightgrey};
        width: 100%;
        margin-top: ${unitize(2)};
        padding: 0 ${unitize(10)} 0 ${unitize(30)};
        align-items: center;
        justify-content: space-between;
        ${({ availableToAdd }) =>
            availableToAdd === false &&
            css`
                background-color: ${(props) => props.theme.color.lightestgrey};
                color: ${(props) => props.theme.color.darkgrey};
                padding-left: ${unitize(68)};
            `}
    `,
    Status: styled.p`
        justify-self: end;
        width: ${unitize(120)};
        ${media("xs")} {
            width: ${unitize(240)};
        }
        > span {
            font-weight: ${(props) => props.theme.typography.weight.bold};
        }
    `,
    CheckboxWrapper: styled.div`
        margin-right: ${unitize(11)};
    `,
    ContentWrapper: styled.div`
        display: flex;
        align-items: center;
    `,
};

import { Checkbox, IconProduct, media, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { IArticleRead } from "../../../entities/Article";
import { DocumentationController } from "../../../pages/Documentation/DocumentationController";
import { getController } from "../../../stores/controller/ControllerFactory";
import { WaitingForValue } from "../../../stores/SuperStore";
import { isDefined } from "../../../utils/isDefined";
import { isNullish } from "../../../utils/isNullish";
import Counter from "../../Counter";
import PresignedImage from "../../PresignedImage";
import { AddArticleController } from "../AddArticleController";
import CardInstalledArticle from "./CardInstalledArticle";
import { useConfig } from "../../../config/Config";

interface CardArticleProps {
    article: IArticleRead;
    articleCount?: number;
    /** show count when selected? */
    showArticleCounter?: boolean;
    /** if true, background color changes and counter is rendered - toggle when component is clicked */
    expanded?: boolean;
    /** for x in "Anzahl: x/y" */
    amount?: number;
    /** for y in "Anzahl: x/y" */
    amountTotal?: number;
    whenArticleCountChanges?: (count: number) => void;
    whenExpandedChanges?: (expanded: boolean) => void;
    disabled?: boolean;
    reloadUrl: () => void;
    waitingForReloadUrl?: WaitingForValue;
    firstItem?: boolean;
    displayCheckbox: boolean;
    maxCount?: number;
    displayInstalledArticles?: boolean;
    installedArticles?: IArticleOverviewItem["installedArticles"];
    onlyDisplayValueofCounter?: boolean;
    whenSelectChanges: (selected: boolean) => void;
}

const CardArticle: FunctionComponent<CardArticleProps> = (props: CardArticleProps) => {
    const { controller: modalController } = getController(AddArticleController);
    const { controller } = getController(DocumentationController);
    const counterRef = useRef<HTMLDivElement>(null);
    const [waitingForReloadUrl, setWaitingForReloadUrl] = useState(props.waitingForReloadUrl);
    const isExpanded = props.expanded ?? false;
    const theme = useTheme();
    const { getFileSrc } = useConfig();

    useEffect(() => {
        setWaitingForReloadUrl(props.waitingForReloadUrl);
    }, [props.waitingForReloadUrl]);

    const getAmountofInstalledArticlesSelected = useCallback(
        (installedArticles: IArticleOverviewItem["installedArticles"]) => {
            let count = 0;
            installedArticles.forEach(
                (ia) =>
                    modalController.availableInstalledArticlesSelected.has(ia.id) &&
                    modalController.isInstalledArticleAvailableToAdd(ia, controller.documentation?.type) &&
                    count++
            );

            return count;
        },
        [props.installedArticles, props.expanded, modalController.availableInstalledArticlesSelected]
    );

    const onCardSelectedChange = (selected: boolean, installedArticleId: number) => {
        const articleId = props.installedArticles?.[0].articleId;
        if (selected) {
            //select current installedArticle
            modalController.selectAvailableInstalledArticles([installedArticleId]);

            //if article of current installedArticle is not selected, select it
            if (
                isDefined(props.installedArticles) &&
                isDefined(articleId) &&
                !modalController.availableArticlesSelected.has(props.installedArticles[0].articleId)
            ) {
                modalController.selectAvailableArticle(articleId, controller.documentation?.type, installedArticleId);
            }
        } else {
            //deselect current installedArticle
            modalController.deselectAvailableInstalledArticles([installedArticleId]);

            //check if any installedArticle of currently expanded article is selected, if not deselect said article
            const installedArticlesIds = props.installedArticles?.map((ia) => ia.id);
            const turnSetToArray = Array.from(modalController.availableInstalledArticlesSelected);
            const isOneInstalledArticleSelected = turnSetToArray.some((id) => installedArticlesIds?.includes(id));

            if (isOneInstalledArticleSelected === false && isDefined(articleId)) {
                modalController.deselectAvailableArticle(articleId);
            }
        }
    };

    //#region event listeners
    const onCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (
            counterRef.current !== null &&
            (e.target === counterRef.current || counterRef.current.contains(e.target as HTMLElement))
        ) {
            // don't call whenExpandedChanges, when user clicked on Counter
            return;
        }
        props.whenExpandedChanges !== undefined && props.whenExpandedChanges(!isExpanded);
    };

    const onCountChange = (count: number) => {
        //TODO
        if (props.whenArticleCountChanges !== undefined) {
            if (count >= 1) {
                props.whenArticleCountChanges(count);
            } else {
                props.whenArticleCountChanges(1);
            }
        }
    };
    //#endregion event listeners

    return (
        <S.ComponentWrapper displayInstalledArticles={props.displayInstalledArticles}>
            <S.Component expanded={props.expanded} onClick={onCardClick} disabled={props.disabled} {...props}>
                <S.Content>
                    {props.displayCheckbox !== false && props.amount !== undefined && (
                        <span onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                onChange={props.whenSelectChanges}
                                checked={modalController.availableArticlesSelected.has(props.article.id)}
                            />
                        </span>
                    )}
                    <S.ImageWrapper>
                        {typeof props.article.imageUrl === "string" ? (
                            <PresignedImage
                                url={getFileSrc(props.article.imageUrl)}
                                reloadUrl={() => props.reloadUrl()}
                                waitingForValue={waitingForReloadUrl}
                                style={{ height: "100%", width: "100%" }}
                                disableRefresh={!(props.firstItem ?? false)}
                            />
                        ) : (
                            <S.NoImage>
                                <IconProduct color={theme.color.darkgrey} />
                            </S.NoImage>
                        )}
                    </S.ImageWrapper>
                    <S.TextColumn>
                        <h4>{props.article.name}</h4>
                        <p>{props.article.number !== "" ? props.article.number : "Keine Artikelnummer angegeben"}</p>
                        {props.amount !== undefined ? (
                            <p>
                                Anzahl:
                                <S.AmountInstalled>
                                    {props.amount}/{props.amountTotal}
                                </S.AmountInstalled>
                            </p>
                        ) : (
                            <p>
                                am Bereich:
                                <S.AmountInstalled>{props.amountTotal}</S.AmountInstalled>
                            </p>
                        )}
                    </S.TextColumn>
                </S.Content>
                {props.showArticleCounter !== false && props.articleCount && props.disabled !== true && (
                    <S.CounterWrapper className={"uf-amount"} ref={counterRef}>
                        <Counter
                            onlyDisplayValue={props.onlyDisplayValueofCounter ?? !isExpanded}
                            count={
                                props.installedArticles !== undefined
                                    ? getAmountofInstalledArticlesSelected(props.installedArticles)
                                    : props.articleCount
                            }
                            onCountChange={onCountChange}
                            maxCount={!isNullish(props.maxCount) ? props.maxCount : 3000}
                        />
                    </S.CounterWrapper>
                )}
            </S.Component>

            {props.displayInstalledArticles !== false &&
                props.installedArticles !== undefined &&
                props.installedArticles.length > 0 &&
                props.installedArticles.map((ia) => (
                    <CardInstalledArticle
                        key={ia.id}
                        installedArticle={ia}
                        availableToAdd={modalController.isInstalledArticleAvailableToAdd(
                            ia,
                            controller.documentation?.type
                        )}
                        whenSelectChanges={(selected) => onCardSelectedChange(selected, ia.id)}
                    />
                ))}
        </S.ComponentWrapper>
    );
};

export default observer(CardArticle);

//#region styles
const S = {
    Component: styled.div<{ expanded?: boolean; disabled?: boolean }>`
        background-color: ${(props) => props.theme.color.white};
        ${({ expanded }) =>
            (expanded ?? false) &&
            css`
                background-color: ${(props) => props.theme.color.lightgrey};
                .counter {
                    background-color: ${(props) => props.theme.color.white};
                }
            `}
        min-height: ${unitize(112)};
        display: flex;

        column-gap: ${unitize(10)};
        padding: ${unitize(10)};
        cursor: pointer;
        flex-direction: column;
        ${media("md")} {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        @media (hover: hover) {
            &:hover {
                background-color: ${(props) => props.theme.color.lightgrey};
                .counter {
                    background-color: ${(props) => props.theme.color.white};
                }
            }
        }

        ${({ disabled }) =>
            (disabled ?? false) &&
            css`
                cursor: default;
                opacity: 0.25;

                @media (hover: hover) {
                    &:hover {
                        background-color: unset;
                        .counter {
                            cursor: default;
                            background-color: ${(props) => props.theme.color.lightgrey};
                        }
                    }
                }
            `}
    `,
    Content: styled.div`
        height: fit-content;
        display: flex;
        align-items: center;
        column-gap: ${unitize(10)};
    `,
    ImageWrapper: styled.div`
        width: ${unitize(64)};
        height: ${unitize(64)};
        /* make sure nothing overflows. Especially when image is not found, the alt text ignores width */
        overflow: hidden;
        ${media("md")} {
            width: ${unitize(92)};
            height: ${unitize(92)};
        }
    `,
    Image: styled.img`
        width: 100%;
        height: 100%;
    `,
    NoImage: styled.div`
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
        /* background: ${(props) => props.theme.color.lightestgrey}; */
    `,
    AmountInstalled: styled.span`
        font-weight: 600;
        margin-left: ${unitize(5)};
    `,
    TextColumn: styled.div`
        flex: 1;
        p {
            font-size: ${unitize(14)};
            line-height: 1.5;
        }
    `,
    CounterWrapper: styled.div`
        align-self: flex-end;
    `,
    ComponentWrapper: styled.div<{ displayInstalledArticles?: boolean }>`
        height: fit-content;
        ${({ displayInstalledArticles }) =>
            (displayInstalledArticles ?? false) &&
            css`
                display: flex;
                flex-direction: column;
            `}
    `,
};
//#endregion styles

import { Button, SelectBox } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { ICompanyRead } from "../../../entities/Company";
import { companyService } from "../../../services/CompanyService";
import { transferKeyService } from "../../../services/TransferKeyService";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { BuildingListController } from "../../BuildingList/BuildingListController";
import { useConfig } from "../../../config/Config";

interface DirectTransferIfAbsAdminProps {
    selectedBuildingId: number;
}

const DirectTransferIfAbsAdmin: FunctionComponent<DirectTransferIfAbsAdminProps> = (
    props: DirectTransferIfAbsAdminProps
) => {
    const [loading, setLoading] = useState(true);
    const [absCompanies, setAbsCompanies] = useState([] as ICompanyRead[]);
    const [selectedCompany, setSelectedCompany] = useState("Unternehmen auswählen");
    const isAbsAdmin = session.hasAbsAdminRights;
    const history = useHistory();
    const { controller: controllerBuildingList } = getController(BuildingListController);
    const config = useConfig();

    const getAllAbsCompanies = async () => {
        try {
            const res = await companyService.fetchAllAbsCompanies();
            if (res.result !== null) {
                setAbsCompanies(res.result);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            throw Error("Cannot fetch all abs companies");
        }
    };

    useEffect(() => {
        transferKeyService.getTransferKeys();
        if (isAbsAdmin) {
            getAllAbsCompanies();
        }
    }, [isAbsAdmin]);

    const onChange = (selectedCompany: string) => {
        setSelectedCompany(selectedCompany);
    };

    const onTransferClick = async () => {
        history.push(`${config.docu.path_prefix}/`);
        transferKeyService.createAndRedeem({
            building: `buildings/${props.selectedBuildingId}`,
            companyId: selectedCompany,
        });
        controllerBuildingList.init({ page: 1 });
    };

    if (!isAbsAdmin) {
        return null;
    }

    if (loading) {
        return <FieldLoading text="Direkt Transfer wird geladen..." height={"100%"} />;
    }

    return (
        <>
            <SelectBox
                label={"ABS Unternehmen"}
                options={absCompanies.map((company) => {
                    return { text: company.name, value: company.id.toString() };
                })}
                placeholder={"Bitte wählen"}
                value={selectedCompany}
                onChange={onChange}
                enableSearch={true}
            />
            <S.ButtonWrapper>
                <Button size={"small"} variant={"outline"} color={"black"}>
                    <button onClick={() => onTransferClick()}>Direkt transferieren</button>
                </Button>
            </S.ButtonWrapper>
        </>
    );
};

export default observer(DirectTransferIfAbsAdmin);

//#region styles
const S = {
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        button {
            margin-right: 20px;
        }
        button:last-child {
            margin-right: 0;
        }
    `,
};
//#endregion styles

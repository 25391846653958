import { unitize } from "@abs-safety/lock-book-web-ui";
import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface QuestionAnswerContainerProps {
    answer: JSX.Element;
    question: string;
    isTextarea?: boolean;
}

const QuestionAnswerContainer: FunctionComponent<QuestionAnswerContainerProps> = (
    props: QuestionAnswerContainerProps
) => {
    return (
        <S.Component className={props.isTextarea === true ? "isTextarea" : ""}>
            <S.Question>{props.question}</S.Question>
            <S.Answer>{props.answer}</S.Answer>
        </S.Component>
    );
};

export default QuestionAnswerContainer;

//#region styles
const S = {
    Component: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-size: ${unitize(16)};
        height: ${unitize(80)};
        border-color: ${(props) => props.theme.color.grey};
        &.isTextarea {
            height: auto;
            min-height: ${unitize(80)};
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 1rem 0;
            box-sizing: border-box;
        }
    `,
    Question: styled.span`
        font-weight: ${(props) => props.theme.typography.weight.regular};
        flex: 0 0 65%;
    `,
    Answer: styled.span`
        flex: 0 0 35%;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .isTextarea & {
            flex: 0 0 100%;
            text-align: left;
        }
    `,
};
//#endregion styles

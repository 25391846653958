import { Button, FieldEmpty, IconProduct, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { adminStore } from "../../../pages/Admin/AdminStore";
import { getController } from "../../../stores/controller/ControllerFactory";
import FieldLoading from "../../FieldLoading";
import { AddArticleController, SubViewKey } from "../AddArticleController";

interface Step1ContentWhenNoArticlesProps {
    searchIsActive: boolean;
    filterIsActive: boolean;
}

const Step1ContentWhenNoArticles: FunctionComponent<Step1ContentWhenNoArticlesProps> = (
    props: Step1ContentWhenNoArticlesProps
) => {
    const { controller: modalController } = getController(AddArticleController);

    let text = "";
    switch (modalController.subView) {
        case SubViewKey.FallProtection:
            text = "Kein Produkt in der Kategorie Absturzsicherung vorhanden";
            break;
        case SubViewKey.Misc:
            text = "Kein Produkt in der Kategorie Gesamtdacht vorhanden.";
            break;
        case SubViewKey.Climb:
            text = "Kein Produkt in der Kategorie Steigtechnik vorhanden.";
            break;
        case SubViewKey.Custom:
            text = "Zur Erstellung eigener Produkte.";
            break;
        case SubViewKey.Available:
            text = "Kein Produkt in der Produktverwaltung vorhanden. Bitte lege ein neues\u00A0Produkt\u00A0an.";
            break;
        case SubViewKey.Drainage:
            text = "Kein Produkt in der Kategorie Entwässerung vorhanden.";
            break;
        case SubViewKey.Ventilation:
            text = "Kein Produkt in der Kategorie Lüftung vorhanden.";
            break;

        default:
            throw Error("subView had unexpected value");
    }

    const filterOrSearchActive =
        modalController.categoryFilter.size > 0 || props.searchIsActive || props.filterIsActive;

    if (modalController.isWaitingFor("loadBuildingAreaArticleOverview")) {
        return <FieldLoading text="Produkte werden geladen..." />;
    }
    /* articles were loaded but no articles are visible, why? */
    if (filterOrSearchActive) {
        return (
            /* search or filter (productType, category) got 0 results */
            <FieldEmpty icon={<IconProduct height={48} width={48} />} text="Suche oder Filter ergab keinen Treffer." />
        );
    }
    if (modalController.subView === SubViewKey.Custom) {
        return (
            <>
                <FieldEmpty
                    icon={<IconProduct height={48} width={48} />}
                    text={"Sie haben noch keinen eigen Produkte angelegt."}
                />
                <Button variant="outline" color="black" size="small" style={{ marginTop: unitize(30) }}>
                    <Link to={"/doku/admin/products"} onClick={() => adminStore.saveBackToDocuPath()}>
                        {text}
                    </Link>
                </Button>
            </>
        );
    }
    /* else: no articles available in this Tab ... */
    return <FieldEmpty icon={<IconProduct height={48} width={48} />} text={text} />;
};

export default observer(Step1ContentWhenNoArticles);

//#endregion styles

import { Button, FieldEmpty, FormControl, IconCopy, IconDocument, Input, unitize } from "@abs-safety/lock-book-web-ui";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef, useState } from "react";
import styled from "styled-components";
import { DocumentationType } from "../../entities/Documentation";
import { buildingService } from "../../services/BuildingService";
import { dataLayerService } from "../../services/DataLayerService";
import { documentationService } from "../../services/DocumentationService";
import { documentationSharingHashService } from "../../services/DocumentationSharingHashService";
import { documentService } from "../../services/DocumentService";
import { session } from "../../session/Session";
import { copyPdfLink } from "../../utils/copyPdfLink";
import { forceHttps } from "../../utils/forceHttps";
import { formatDate } from "../../utils/formatDate";
import { getSharingLinkOfHash } from "../../utils/getSharingLinkOfHash";
import { isDefined } from "../../utils/isDefined";
import { isNullish } from "../../utils/isNullish";
import FieldLoading from "../FieldLoading";
import ModalNewCertificate, { FormValues } from "./ModalNewCertificate";
import { useConfig } from "../../config/Config";

interface CertificateViewProps {
    documentationId: number;
    buildingId: number;
    waitingForCreateSharingHash: boolean;
    waitingForUpdateDocumentation: boolean;
    onGenerateSharingLink: () => void;
    onCreateCertificate: (values: FormValues) => Promise<unknown>;
}

const CertificateView: FunctionComponent<CertificateViewProps> = (props: CertificateViewProps) => {
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const documentation = documentationService.get(props.documentationId);
    const building = buildingService.get(props.buildingId);
    const { getFileSrc } = useConfig();

    const latestCertificate = documentService.getLastDocumentByDocumentation(
        props.documentationId,
        "installation-certificate"
    );

    const latestSharingLink = documentationSharingHashService.getNewestSharingHashOf(
        props.documentationId,
        "installation-certificate"
    );

    const isLatestSharingLinkExpired = isDefined(latestSharingLink)
        ? new Date(latestSharingLink.expiresAt).getTime() < Date.now()
        : false;

    if (isNullish(documentation) || isEmpty(session.constraints)) {
        return <FieldLoading text="Dokumente werden geladen..." />;
    }

    if (!documentation.isCompleted) {
        return (
            <S.ContentWrapper>
                <FieldEmpty text="Die Dokumentation muss erst abgeschlossen werden" />
            </S.ContentWrapper>
        );
    }

    return (
        <>
            {documentModalOpen && (
                <ModalNewCertificate
                    onCloseClick={() => setDocumentModalOpen(false)}
                    onSubmit={(values) => {
                        props.onCreateCertificate(values).then(() => {
                            setDocumentModalOpen(false);
                        });
                    }}
                    projectNumber={building?.projectNumber ?? ""}
                    documentationId={documentation.id}
                    waitingForUpdateDocumentation={props.waitingForUpdateDocumentation}
                />
            )}
            <S.ContentWrapper>
                {isNullish(latestCertificate) && (
                    <S.ContentWrapperEmpty>
                        <S.FormWrapper>
                            <FieldEmpty icon={<IconDocument height={48} width={48} />} text="Kein Dokument vorhanden" />
                        </S.FormWrapper>
                    </S.ContentWrapperEmpty>
                )}
                {isDefined(latestCertificate) && (
                    <S.FormWrapper>
                        <FormControl>
                            <Input
                                label="Erstellungsdatum"
                                type="text"
                                readOnly={true}
                                value={formatDate(latestCertificate.createdAt)}
                            />
                        </FormControl>
                        <FormControl>
                            <Input
                                label="Projektnummer"
                                type="text"
                                readOnly={true}
                                value={building?.projectNumber ?? ""}
                            />
                        </FormControl>
                    </S.FormWrapper>
                )}
                <S.ButtonWrapper>
                    <Button variant="outline" color="black" size="small">
                        <button onClick={() => setDocumentModalOpen(true)}>
                            {buttonNames.openModalNewCertificate(documentation.type, latestCertificate !== undefined)}
                        </button>
                    </Button>

                    {isDefined(latestCertificate) && (
                        <Button variant="fill" color="black" size="small">
                            <a
                                className="uf-openPdf"
                                href={getFileSrc(forceHttps(latestCertificate.imageUrl))}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                    dataLayerService.emitOpenPdf({
                                        category: "building",
                                        docuType: documentation.type,
                                        documentType: latestCertificate.type,
                                    })
                                }
                            >
                                PDF öffnen
                            </a>
                        </Button>
                    )}
                </S.ButtonWrapper>

                {isDefined(latestCertificate) && (
                    <>
                        <h4 style={{ marginTop: "4rem", marginBottom: "1.3rem" }}>PDF Link</h4>
                        <p>
                            Generiere einen 60 Tage gültigen Link um diesen zu teilen.
                            <br />
                            Nach Ablauf muss ein neuer Link generiert werden.
                        </p>
                        <S.FormWrapper>
                            <FormControl>
                                <Input
                                    label="Ablaufdatum"
                                    type="text"
                                    readOnly={true}
                                    value={isDefined(latestSharingLink) ? formatDate(latestSharingLink.expiresAt) : ""}
                                />
                            </FormControl>
                            <FormControl>
                                <S.PdfUrl ref={hiddenFileInputRef}>
                                    <Input
                                        label={
                                            documentation.type === "assembly"
                                                ? "Link zum Installationszertifikat"
                                                : "Link zur Prüfbescheinigung"
                                        }
                                        type="text"
                                        value={
                                            isDefined(latestSharingLink)
                                                ? getSharingLinkOfHash(latestSharingLink.hash)
                                                : ""
                                        }
                                        error={isLatestSharingLinkExpired}
                                        errorMessage="Link ist nicht mehr gültig. Bitte generiere einen neuen Link."
                                        readOnly={true}
                                    />
                                    {isDefined(latestSharingLink) && (
                                        <S.CopyIconWrapper onClick={() => copyPdfLink(hiddenFileInputRef)}>
                                            <IconCopy width={24} height={24} />
                                        </S.CopyIconWrapper>
                                    )}
                                </S.PdfUrl>
                            </FormControl>
                            <S.ButtonWrapper>
                                <Button
                                    variant="outline"
                                    color="black"
                                    size="small"
                                    disabled={props.waitingForCreateSharingHash}
                                >
                                    <button onClick={props.onGenerateSharingLink}>Link generieren</button>
                                </Button>
                            </S.ButtonWrapper>
                        </S.FormWrapper>
                    </>
                )}
            </S.ContentWrapper>
        </>
    );
};

export default observer(CertificateView);

const buttonNames = {
    openModalNewCertificate: (documentationType: DocumentationType, certificateAlreadyExists: boolean) => {
        if (documentationType === "assembly") {
            return certificateAlreadyExists
                ? "Installationszertifikat aktualisieren"
                : "Installationszertifikat erstellen";
        }

        return certificateAlreadyExists ? "Prüfbescheinigung aktualisieren" : "Prüfbescheinigung erstellen";
    },
};

//#region styles
const S = {
    ButtonWrapper: styled.div`
        display: flex;
        justify-content: flex-end;
        gap: ${unitize(20)};
    `,
    ContentWrapperEmpty: styled.div`
        margin-top: ${unitize(60)};
    `,
    ContentWrapper: styled.div`
        margin-top: ${unitize(40)};
    `,
    FormWrapper: styled.div`
        margin-top: ${unitize(40)};
        margin-bottom: ${unitize(30)};
    `,
    PdfUrl: styled.div`
        display: flex;
        align-items: flex-start;
        position: relative;
        input {
            padding-right: ${unitize(20)};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        label {
            width: 100%;
        }
        svg {
            position: absolute;
            top: 2rem;
        }
    `,
    CopyIconWrapper: styled.div`
        cursor: pointer;
        height: ${unitize(24)};
        width: ${unitize(24)};
    `,
};
//#endregion styles

import { Button, IconTrash, Popup, unitize } from "@abs-safety/lock-book-web-ui";
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import styled, { css } from "styled-components";
import {
    IArticleQuestionBase,
    IArticleQuestionRead,
    IArticleQuestionWrite,
    QuestionType,
} from "../../../../entities/ArticleQuestion";
import { DocumentationType } from "../../../../entities/Documentation";
import { articleAttachmentService } from "../../../../services/ArticleAttachmentService";
import { articleQuestionService } from "../../../../services/ArticleQuestionService";
import { articleService } from "../../../../services/ArticleService";
import { notificationHandler } from "../../../../session/NotificationHandler";
import { getQuestionTypeName } from "../../../../utils/getQuestionTypeName";
import { isDefined } from "../../../../utils/isDefined";
import { isNotEmptyString } from "../../../../utils/isNotEmptyString";
import { ProductFormPageProps } from "../ProductFormPage";
import { ProductFormFooter } from "./ProductFormFooter";
import { ProductFormGrid } from "./ProductFormGrid";
import QuestionForm, { FormValues } from "./QuestionForm";

interface ProductFormStep2Props {
    fetchedQuestions: IArticleQuestionRead[];
    onSave: (questions: IArticleQuestionWrite[], questionsToDelete?: number[]) => void;
    onBack: () => void;
    pageType: ProductFormPageProps["pageType"];
}

//negative tempIds to not collide with existing Ids of props.questions
let tempIdCounter = -1;
const getNewId = () => tempIdCounter--;

type QuestionData = FormValues & { id: QuestionId };
const defaultQuestion: QuestionData = {
    id: getNewId(),
    question: "",
    documentationType: "assembly",
    invalid: false,
    isNew: true,
};

type QuestionId = IArticleQuestionBase["id"];

const defaultNewQuestions = new Map([[defaultQuestion.id, defaultQuestion]]);

const ProductFormStep2: FunctionComponent<ProductFormStep2Props> = (props: ProductFormStep2Props) => {
    const [questions, setQuestions] = useState(defaultNewQuestions);

    const [assemblyOrder, setAssemblyOrder] = useState<QuestionId[]>([defaultQuestion.id]);
    const [maintenanceOrder, setMaintenanceOrder] = useState<QuestionId[]>([]);
    const [selected, setSelected] = useState<QuestionId>(
        props.fetchedQuestions.length > 0 ? props.fetchedQuestions[0].id : defaultQuestion.id
    );
    const [productQuestionsToDelete, setProductQuestionsToDelete] = useState<QuestionId[]>([]);

    /** props.fetchedQuestions ? -> set questions and order arrays */
    useEffect(() => {
        if (props.fetchedQuestions.length < 0) return;
        const questionsAsQuestionData: QuestionData[] = props.fetchedQuestions.map((q) => {
            return { ...q, invalid: false };
        });
        const questionsAsMap = new Map(
            questionsAsQuestionData.map((q) => {
                return [q.id, q];
            })
        );

        if (props.pageType === "edit" && props.fetchedQuestions.length > 0) {
            const assemblyQuestions = props.fetchedQuestions
                .filter((q) => q.documentationType === "assembly")
                .sort((a, b) => {
                    return a.position - b.position;
                });
            const maintenanceQuestions = props.fetchedQuestions
                .filter((q) => q.documentationType === "maintenance")
                .sort((a, b) => {
                    return a.position - b.position;
                });
            setQuestions(questionsAsMap);
            setAssemblyOrder(assemblyQuestions.map((q) => q.id));
            setMaintenanceOrder(maintenanceQuestions.map((q) => q.id));
            setSelected(questionsAsMap.entries().next().value[0]);
        }
    }, [props.fetchedQuestions]);

    /**
     * Checks if whole form (with all questions) is valid and ...
     *
     * - marks invalid questions (set question.invalid)
     * - show Error Toast
     *
     * @returns is whole form (with all questions) valid?
     * */
    const validateQuestionsOnSubmit = (
        questions: Map<number, QuestionData>,
        setQuestions: React.Dispatch<React.SetStateAction<Map<number, QuestionData>>>
    ): boolean => {
        let hasError = false;
        if (questions.size === 0) {
            hasError = true;
        }
        questions.forEach((question) => {
            const isInvalid = !isQuestionValid(question);
            question.invalid = isInvalid;
            if (isInvalid) {
                hasError = true;
            }
        });
        setQuestions(new Map(questions));

        if (hasError) {
            notificationHandler.addNotification({
                type: "error",
                title: "Ungültige Fragen",
                description:
                    "Es müssen alle Pflichtangaben aller Fragen ausgefüllt sein und es muss mindestens eine Frage existieren.",
            });
        }

        return !hasError;
    };

    // not valid: question
    const isQuestionValid = (question: QuestionData) => {
        const hasNoItems = !(isDefined(question.items) && question.items.length > 0);
        const isListWithNoItems = question.questionType === "list" && hasNoItems;

        return (
            !isListWithNoItems &&
            isNotEmptyString(question.question) &&
            isNotEmptyString(question.documentationType) &&
            isNotEmptyString(question.questionType)
        );
    };

    const onSubmit = () => {
        const isValid = validateQuestionsOnSubmit(questions, setQuestions);
        if (!isValid) {
            return;
        }

        /** transform local state (order array & QuestionData) to array of data, which is required by API */
        const parseQuestionDataForSubmit = (orderArray: QuestionId[]): IArticleQuestionWrite[] => {
            const isQuestion = (item: QuestionData | undefined): item is QuestionData => item !== undefined;
            const questionsOfArray = orderArray.map((id) => questions.get(id)).filter(isQuestion);
            return questionsOfArray.map((question, index) => ({
                id: question.isNew === true ? undefined : question.id,
                position: index + 1,
                question: question.question,

                // ignore undefined, as it's validated in application logic before. If not: API doesn't allow anyway
                questionType: question.questionType as QuestionType,
                defaultAnswer: question.defaultAnswer,
                documentationType: question.documentationType,
                isMandatory: question.isMandatory,
                items: question.items,
                max: question.max,
                min: question.min,
            }));
        };
        const questionsToCreate = [
            ...parseQuestionDataForSubmit(assemblyOrder),
            ...parseQuestionDataForSubmit(maintenanceOrder),
        ];
        props.onSave(questionsToCreate, productQuestionsToDelete);
    };

    const onQuestionChange = (values: FormValues) => {
        // documentationType of updated question changed? -> move to other order array
        const docuTypeBefore = questions.get(selected)?.documentationType;
        const docuTypeAfter = values.documentationType;
        if (docuTypeAfter !== docuTypeBefore) {
            const removeFrom = docuTypeBefore === "assembly" ? setAssemblyOrder : setMaintenanceOrder;
            const addTo = docuTypeAfter === "assembly" ? setAssemblyOrder : setMaintenanceOrder;
            removeFrom((ids) => ids.filter((id) => id !== selected));
            addTo((ids) => [...ids, selected]);
        }

        setQuestions((questions) => {
            if (values.invalid === true) {
                const becameValid = isQuestionValid(values);
                if (becameValid) {
                    values.invalid = false;
                }
            }
            const updated = questions.set(selected, values);
            const clone = new Map(updated);
            return clone;
        });
    };

    const onSelectQuestion = (id: QuestionId) => {
        setSelected(id);
    };

    const onDeleteQuestion = (id: QuestionId) => {
        const question = questions.get(id);
        const removeFrom = question?.documentationType === "assembly" ? setAssemblyOrder : setMaintenanceOrder;
        removeFrom((ids) => ids.filter((_id) => _id !== id));
        setQuestions((questions) => {
            questions.delete(id);
            return new Map(questions);
        });
        setProductQuestionsToDelete((state) => [...state, id]);
    };

    const onNewQuestion = (documentationType: DocumentationType) => {
        const id = getNewId();
        setQuestions(new Map(questions.set(id, { id, documentationType, question: "", isNew: true })));
        const addTo = documentationType === "assembly" ? setAssemblyOrder : setMaintenanceOrder;
        addTo((ids) => [...ids, id]);
        setSelected(id);
    };

    const onBack = () => {
        props.onBack();
    };

    return (
        <>
            <ProductFormGrid>
                <div>
                    <QuestionCardList
                        headline="Montagefragen"
                        questionOrder={assemblyOrder}
                        selected={selected}
                        questions={questions}
                        onSelectQuestion={onSelectQuestion}
                        onDeleteQuestion={onDeleteQuestion}
                        onNewQuestion={() => onNewQuestion("assembly")}
                        setQuestionOrder={setAssemblyOrder}
                    />
                    <QuestionCardList
                        headline="Wartungsfragen"
                        questionOrder={maintenanceOrder}
                        selected={selected}
                        questions={questions}
                        onSelectQuestion={onSelectQuestion}
                        onDeleteQuestion={onDeleteQuestion}
                        onNewQuestion={() => onNewQuestion("maintenance")}
                        setQuestionOrder={setMaintenanceOrder}
                    />
                </div>
                <div>
                    <S.FormCard>
                        <QuestionForm onChange={onQuestionChange} key={selected} values={questions.get(selected)} />
                    </S.FormCard>
                </div>
            </ProductFormGrid>
            <ProductFormFooter>
                <Popup
                    popupButton={{
                        onClick: props.onBack,
                        children: "Ja, zurück",
                        className: "uf-confirmBackToStep1",
                    }}
                    popupContent={
                        <p style={{ marginBottom: 20 }}>
                            Sicher zurück zu Schritt 1? Änderungen hier würden verloren gehen.
                        </p>
                    }
                    position={{ bottom: "60px", right: "0" }}
                    toggleNode={
                        <Button color="black">
                            <button className="uf-backToStep1" type="button">
                                Zurück
                            </button>
                        </Button>
                    }
                />
                <Button>
                    <button
                        type="button"
                        onClick={onSubmit}
                        className="uf-createOrSaveProduct"
                        disabled={
                            articleAttachmentService.waitingFor.update === true ||
                            articleQuestionService.waitingFor.createBatch === true ||
                            articleService.waitingFor.update === true ||
                            articleAttachmentService.waitingFor.deleteMultiple === true ||
                            articleQuestionService.waitingFor.deleteMultiple === true ||
                            articleQuestionService.waitingFor.updateMultiple === true ||
                            articleService.waitingFor.create === true
                        }
                    >
                        Produkt {props.pageType === "create" ? `anlegen` : `speichern`}
                    </button>
                </Button>
            </ProductFormFooter>
        </>
    );
};

export default ProductFormStep2;

const QuestionCardList = (props: {
    headline: string;
    questionOrder: QuestionId[];
    selected: QuestionId;
    questions: Map<QuestionId, QuestionData>;
    onSelectQuestion: (id: QuestionId) => void;
    onNewQuestion: () => void;
    onDeleteQuestion: (id: QuestionId) => void;
    setQuestionOrder: Dispatch<SetStateAction<number[]>>;
}) => (
    <>
        <S.QuestionListHeader>{props.headline}</S.QuestionListHeader>
        {isDefined(props.questionOrder) && props.questionOrder.length > 0 && (
            <S.QuestionList>
                <ReactSortable
                    touchStartThreshold={100}
                    delay={300}
                    delayOnTouchOnly={true}
                    animation={100}
                    easing={"cubic-bezier(1, 0, 0, 1)"}
                    className={"sortingQuestionsContainer"}
                    list={props.questionOrder.map((id) => ({ id }))}
                    setList={(items) => props.setQuestionOrder(items.map((item) => item.id))}
                >
                    {props.questionOrder.map((id) => (
                        <QuestionCard
                            key={id}
                            selected={id === props.selected}
                            onClick={() => props.onSelectQuestion(id)}
                            question={props.questions.get(id)}
                            onDeleteQuestion={() => props.onDeleteQuestion(id)}
                            isInvalid={props.questions.get(id)?.invalid}
                        />
                    ))}
                </ReactSortable>
            </S.QuestionList>
        )}
        <Button size="small" variant="outline" className="uf-addNewQuestion">
            <button type="button" onClick={props.onNewQuestion}>
                + Frage anlegen
            </button>
        </Button>
    </>
);

const QuestionCard = (props: {
    selected: boolean;
    question?: QuestionData;
    onClick: () => void;
    onDeleteQuestion: () => void;
    isInvalid?: boolean;
}) => {
    const deleteBtnRef = useRef<HTMLButtonElement>(null);
    const onCardClick = (e: React.MouseEvent) => {
        if (e.target === deleteBtnRef.current || deleteBtnRef.current?.contains(e.target as HTMLElement)) {
            // don't call onClick, when user clicked on deleteBtn
            return;
        }
        props.onClick();
    };
    return (
        <S.QuestionCard
            className="overflow-hidden"
            selected={props.selected}
            isInvalid={props.isInvalid}
            onClick={onCardClick}
        >
            <S.QuestionCardContent>
                <span className="truncate">
                    {props.question?.question}
                    {(props.question?.question ?? "") === "" && (
                        <S.PlaceholderQuestionText>noch kein Fragentext</S.PlaceholderQuestionText>
                    )}
                    {props.question?.isMandatory === true && " *"}
                </span>
                <span className="truncate">{getQuestionTypeName(props.question?.questionType)}</span>
                <S.AttachmentDeleteButton
                    ref={deleteBtnRef}
                    type="button"
                    onClick={props.onDeleteQuestion}
                    className="uf-deleteProductQuestion"
                >
                    <IconTrash height={20} width={20} color="current" />
                </S.AttachmentDeleteButton>
            </S.QuestionCardContent>
        </S.QuestionCard>
    );
};

//#region styles
const S = {
    QuestionListHeader: styled.h4`
        margin-bottom: ${unitize(20)};
        &:not(:first-of-type) {
            margin-top: ${unitize(20)};
        }
    `,
    QuestionList: styled.div`
        .sortingQuestionsContainer {
            row-gap: 6px;
            display: grid;
            margin-bottom: ${unitize(10)};
        }
    `,
    QuestionCard: styled.div<{ selected: boolean; isInvalid?: boolean }>`
        border: 1px solid ${(props) => props.theme.color.grey};
        border-radius: ${(props) => props.theme.borderRadius};
        padding: ${unitize(10)};
        cursor: grab;
        ${(props) =>
            props.selected &&
            css`
                background-color: ${(props) => props.theme.color.white};
                box-shadow: ${(props) => props.theme.shadow.card};
                border-left: 4px solid ${(props) => props.theme.color.primary.main};
            `}
        ${(props) =>
            props.isInvalid === true &&
            css`
                background-color: ${(props) => props.theme.color.error}50;
                .truncate span {
                    color: ${(props) => props.theme.color.error};
                }
            `}
    `,
    QuestionCardContent: styled.div`
        display: grid;
        grid-template-columns: 3fr 1fr 30px;
        align-items: center;
    `,
    PlaceholderQuestionText: styled.span`
        color: ${(props) => props.theme.color.darkgrey};
        font-style: italic;
    `,
    FormCard: styled.div`
        padding: 20px;
        box-shadow: ${(props) => props.theme.shadow.card};
        border-radius: ${(props) => props.theme.borderRadius};
        background: white;
        border: 1px solid ${(props) => props.theme.color.grey};
    `,
    AttachmentDeleteButton: styled.button`
        display: inline-flex;
        background-color: transparent;
        border-radius: 50%;
        height: ${unitize(30)};
        width: ${unitize(30)};
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        margin: -5px;
        :hover {
            background-color: ${(props) => props.theme.color.warning};
            fill: white;
        }
    `,
};
//#endregion styles

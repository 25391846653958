import { Button, ButtonGroup, ColumnSizes, IconClose, ListSortable, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { IArticleOverviewItem } from "../../../api/ArticleApi";
import { ConstraintViolationError } from "../../../entities/ErrorResponse";
import { installedArticleService } from "../../../services/InstalledArticleService";
import { notificationHandler } from "../../../session/NotificationHandler";
import { getController } from "../../../stores/controller/ControllerFactory";
import { getInstalledArticlePreviewName } from "../../../utils/getInstalledArticlePreviewName";
import { AreaController } from "../AreaController";
import InstalledArticlesEdit from "./InstalledArticlesEdit/InstalledArticlesEdit";
import InstalledArticlesListCard from "./InstalledArticlesListCard";

const listColumnSizes: ColumnSizes = { xs: [6, 0, 3, 3], md: [4, 2, 2, 4] };

interface InstalledArticlesListProps {
    article: IArticleOverviewItem;
}

const InstalledArticlesList: FunctionComponent<InstalledArticlesListProps> = (props: InstalledArticlesListProps) => {
    const { controller } = getController(AreaController);
    const [editSideModalOpened, setEditSideModalOpened] = useState(false);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    // user clicks on 'select all' -> selectMode is 'all'. Even when user disables many items it's still mode 'all'
    // until he clicks 'select none', than selectMode is 'none'. He can click again on 'select all' (-> mode: 'all')
    // or he can click 'cancel' (-> mode: 'disabled')
    const [selectMode, setSelectMode] = useState<"disabled" | "all" | "none">("disabled");

    //#region event handlers

    const onDeleteMultiple = (installedArticleIds: number[]) => {
        controller.deleteInstalledArticles(installedArticleIds).catch((error: ConstraintViolationError) => {
            // something could not get deleted (could be that a part got deleted and a part not)...

            type ErrorDetails = { message: string; undeletableInstalledArticleIds: string[] };
            const errorDetails: ErrorDetails = JSON.parse(error.result?.detail);

            const undeletableIds = errorDetails.undeletableInstalledArticleIds.map((i) => Number(i));
            const deletableIds = installedArticleIds.filter((id) => !undeletableIds.includes(id));

            // remove installed artcles from store which got deleted succesfully
            deletableIds.forEach((id) => installedArticleService.drop(id));

            // update Selection (Checkboxes)
            setSelectedIds(selectedIds.filter((id) => !deletableIds.includes(id)));

            // show error
            const installedArticles = installedArticleService.getMultiple(installedArticleIds);
            const cannotDelete = installedArticles.filter(
                () => JSON.parse(error.result?.detail).undeletableInstalledArticleIds
            );

            const toastData = cannotDelete.map((cd) =>
                getInstalledArticlePreviewName(cd) !== "" ? getInstalledArticlePreviewName(cd) : undefined
            );

            notificationHandler.addNotification({
                title: "Fehler beim Löschen von Artikeln",
                description:
                    "Installierte Artikel (" +
                    toastData.join(", ") +
                    ") können nicht gelöscht werden, wenn sie einer nicht abgeschlossenen Dokumentation angehören.",
                type: "error",
            });
        });
    };

    const installedArticlesToEdit = props.article.installedArticles.filter((ia) => selectedIds.indexOf(ia.id) !== -1);

    const onEditMultipleClick = () => {
        setEditSideModalOpened(true);
    };

    const onSelectAllClick = () => {
        setSelectMode("all");
        setSelectedIds(props.article.installedArticles.map((ia) => ia.id));
    };

    const onSelectNoneClick = () => {
        setSelectMode("none");
        setSelectedIds([]);
    };

    const onCancelSelectClick = () => {
        setSelectMode("disabled");
        setSelectedIds([]);
    };

    const onEditSideModalClose = () => {
        setEditSideModalOpened(false);
        setSelectedIds([]);
        setSelectMode("disabled");
    };

    const toggleCheckboxItem = (itemId: number) => {
        if (selectedIds.includes(itemId)) {
            setSelectedIds(selectedIds.filter((id) => id !== itemId));
        } else {
            setSelectedIds([...selectedIds, itemId]);
        }
    };

    const onItemClick = (itemId: number) => {
        setSelectedIds([itemId]);
        setEditSideModalOpened(true);
    };

    //#endregion event handlers

    return (
        <S.Component>
            {editSideModalOpened && installedArticlesToEdit.length > 0 && (
                <InstalledArticlesEdit
                    articleName={props.article.name}
                    articleType={props.article.type}
                    installedArticles={installedArticlesToEdit}
                    waitingForDeleteInstalledArticles={controller.waitingFor.deleteInstalledArticles}
                    onClose={onEditSideModalClose}
                    onLastElementDeleted={onEditSideModalClose}
                    deleteInstalledArticles={onDeleteMultiple}
                />
            )}

            <S.SelectMultipleControl>
                <Button variant="text" color="black">
                    {selectMode !== "all" ? (
                        <button onClick={onSelectAllClick}>Alle auswählen</button>
                    ) : (
                        <button onClick={onSelectNoneClick}>Keine auswählen</button>
                    )}
                </Button>
                {selectMode !== "disabled" && (
                    <S.CancelSelect onClick={onCancelSelectClick}>
                        Abbrechen
                        <IconClose height={14} width={14} />
                    </S.CancelSelect>
                )}
            </S.SelectMultipleControl>

            <ListSortable
                headerStyle={{ padding: unitize(10) }}
                listStyle={{ display: "grid", gridGap: unitize(5) }}
                columnSizes={listColumnSizes}
                items={props.article.installedArticles}
                columns={[
                    {
                        title: "Bezeichnung",
                        sortProperty: "appId",
                    },
                    {
                        title: "Hinzugefügt am",
                        sortProperty: "installationDate",
                    },
                    {
                        title: "Anzahl Montagen",
                        sortProperty: "amountAssemblies",
                    },
                    {
                        title: "Anzahl Wartungen",
                        sortProperty: "amountMaintenances",
                    },
                ]}
            >
                {(sortedList) =>
                    sortedList.map((installedArticle) => (
                        <InstalledArticlesListCard
                            key={installedArticle.id}
                            installedArticle={installedArticle}
                            columnSizes={listColumnSizes}
                            selectMode={selectMode}
                            selected={selectedIds.includes(installedArticle.id)}
                            onSelected={() => toggleCheckboxItem(installedArticle.id)}
                            onClick={() => onItemClick(installedArticle.id)}
                            onDeleteClick={() => onDeleteMultiple([installedArticle.id])}
                        />
                    ))
                }
            </ListSortable>
            {selectMode !== "disabled" && (
                <S.ButtonGroup>
                    <ButtonGroup>
                        <Button
                            color="decline"
                            size="small"
                            variant="outline"
                            disabled={selectedIds.length < 1 || controller.waitingFor.deleteInstalledArticles !== false}
                        >
                            <button onClick={() => onDeleteMultiple(selectedIds)}>
                                Löschen ({selectedIds.length})
                            </button>
                        </Button>
                        <Button
                            size="small"
                            disabled={selectedIds.length < 1 || controller.waitingFor.deleteInstalledArticles !== false}
                        >
                            <button onClick={onEditMultipleClick}>Bearbeiten ({selectedIds.length})</button>
                        </Button>
                    </ButtonGroup>
                </S.ButtonGroup>
            )}
        </S.Component>
    );
};

export default observer(InstalledArticlesList);

//#region styles
const S = {
    Component: styled.div`
        margin-top: ${unitize(20)};
        margin-bottom: ${unitize(20)};
        .card {
            min-height: 0;
            padding-top: ${unitize(14)};
            padding-bottom: ${unitize(14)};
            cursor: pointer;
            @media (hover: hover) {
                &:hover {
                    background-color: ${(props) => props.theme.color.lightgrey};
                }
            }
            &.selected {
                background-color: ${(props) => props.theme.color.lightgrey};
            }
            @media (hover: hover) {
                &:hover .hidden-button {
                    display: inline-block;
                }
            }
            &:not(:last-of-type) {
                margin-bottom: ${unitize(5)};
            }
        }
    `,
    SelectMultipleControl: styled.div`
        display: flex;
        justify-content: space-between;
    `,
    CancelSelect: styled.span`
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            margin-left: ${unitize(5)};
        }
        margin-top: ${unitize(18)};
    `,
    ButtonGroup: styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles

import {
    Card,
    ColumnSizes,
    GridColumns,
    IconEdit,
    IconTrash,
    IconUserCircle,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import styled, { useTheme } from "styled-components";
import { IBuildingAttachmentRead } from "../../../entities/BuildingAttachment";
import { session } from "../../../session/Session";
import { formatDate } from "../../../utils/formatDate";

interface CardFileProps {
    disabled?: boolean;
    columnSizes: ColumnSizes;
    file: IBuildingAttachmentRead;
    /** when click on card (except if click is on Menu / Dropdown) */
    onCardClick: () => void;
    onDelete: () => void;
    onEdit: () => void;
}

const CardFile: FunctionComponent<CardFileProps> = (props: CardFileProps) => {
    const theme = useTheme();

    return (
        <Card
            className="card"
            onClick={props.onCardClick}
            disabled={props.disabled}
            isMenuIconFullHeight={true}
            dropdownItems={[
                {
                    icon: <IconEdit />,
                    text: "Bearbeiten",
                    onClick: props.onEdit,
                },
                {
                    icon: <IconTrash />,
                    text: "Löschen",
                    textColor: theme.color.error,
                    onClick: props.onDelete,
                },
            ]}
        >
            <GridColumns
                style={{ height: unitize(28), alignItems: "center" }}
                childrensDisplay="flex"
                columnSizes={props.columnSizes}
            >
                <S.ColName title={props.file.imageFilename}>{props.file.imageFilename}</S.ColName>
                <div>{session.categoryNamesById.get(props.file.categoryId)}</div>
                <S.ColUploaded className="truncate">
                    <IconUserCircle
                        width={29}
                        height={29}
                        firstname={props.file.creator.firstName}
                        lastname={props.file.creator.lastName}
                        picture={props.file.creator.imageUrl}
                    />
                    <span className="truncate">{formatDate(props.file.createdAt)}</span>
                </S.ColUploaded>
            </GridColumns>
        </Card>
    );
};
export default observer(CardFile);

//#region styles
const S = {
    Menu: styled.div`
        position: absolute;
        right: ${unitize(10)};
        top: ${unitize(10)};
    `,
    ColName: styled.div`
        font-weight: ${(props) => props.theme.typography.weight.bold};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    `,
    ColUploaded: styled.div`
        display: flex;
        align-items: center;
        padding-right: 1.2rem;

        div {
            font-size: ${unitize(14)};
            margin-right: ${unitize(10)};
        }
    `,
    ColArea: styled.div`
        > a {
            color: ${(props) => props.theme.color.primary.main};
            text-decoration: none;
        }
    `,
};
//#endregion styles

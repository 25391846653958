import {
    Button,
    ColumnSizes,
    Dropzone,
    FieldFileUpload,
    IconUpload,
    ListSortable,
    ListSortableRef,
    unitize,
} from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useRef } from "react";
import styled from "styled-components";
import FieldLoading from "../../../components/FieldLoading";
import { localConfig } from "../../../config/localConfig";
import { IBuildingAttachmentRead } from "../../../entities/BuildingAttachment";
import { attachmentService } from "../../../services/AttachmentService";
import { notificationHandler } from "../../../session/NotificationHandler";
import { session } from "../../../session/Session";
import { getController } from "../../../stores/controller/ControllerFactory";
import { validateFileSize } from "../../../utils/validators/file";
import { BuildingController } from "../BuildingController";
import ListFiles from "../components/ListFiles";

const listColumnSizes: ColumnSizes = { xs: [8, 0, 4], md: [6, 3, 3] };

const BuildingViewFiles: FunctionComponent = () => {
    const { controller } = getController(BuildingController);

    const listSortableRef = useRef<ListSortableRef | null>(null);
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    /** called by FieldFileUpload (props.onFilesAccepted) and by onFileInputChange (the extra button) */
    const onFilesSelectedToUpload = (files: File[]) => {
        if (files.length === 0) {
            return;
        }
        if (validateFiles(files)) {
            listSortableRef.current?.clearSorting();

            controller.uploadBuildingAttachment(files.reverse());
        }
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        onFilesSelectedToUpload(Array.from(event.target.files));
        event.target.value = "";
    };

    const validateFiles = (files: FileList | File[]) => {
        for (const file of files) {
            const validated = validateFileSize(file);
            if (validated instanceof Error) {
                notificationHandler.addNotification({
                    title: validated.name,
                    description: validated.message,
                    type: "error",
                });
                return false;
            }
        }
        return true;
    };

    function sortFilesByCategory(a: IBuildingAttachmentRead, b: IBuildingAttachmentRead) {
        const categoryA = session.categoryNamesById.get(a.categoryId);
        const categoryB = session.categoryNamesById.get(b.categoryId);
        if (categoryA !== "" && categoryB === "") return 1;
        if (categoryB !== "" && categoryA === "") return -1;
        if (categoryA === "" && categoryB === "") return 0;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return categoryA!.localeCompare(categoryB!);
    }

    return (
        <>
            <S.Description>Diese Dokumente werden nicht in der PDF Dokumentation angezeigt</S.Description>
            <S.AreasHeader>
                <Button disabled={attachmentService.waitingFor.all !== false}>
                    <button className={"uf-uploadButton"} onClick={() => hiddenFileInputRef.current?.click()}>
                        Datei hochladen
                        <IconUpload width={16} />
                    </button>
                </Button>
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept={localConfig.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                    name="attachment"
                    onChange={onFileInputChange}
                    ref={hiddenFileInputRef}
                    multiple={true}
                />
            </S.AreasHeader>

            {controller.isWaitingFor("loadFiles") ? (
                <FieldLoading text="Dateien werden geladen..." />
            ) : (
                <div className={"uf-uploadArea"}>
                    {controller.isWaitingFor("loadFiles") ? (
                        <FieldLoading text="Dateien werden geladen..." />
                    ) : controller.attachments.length === 0 && !controller.isWaitingFor("uploadBuildingAttachment") ? (
                        <FieldFileUpload
                            multiple={true}
                            accept={localConfig.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                            onFilesAccepted={onFilesSelectedToUpload}
                            text="Zum Hochladen Datei hier ablegen"
                        />
                    ) : (
                        <Dropzone
                            width="100%"
                            multiple={true}
                            accept={localConfig.UPLOAD_ACCEPT_BUILDING_ATTACHMENT}
                            onFilesAccepted={onFilesSelectedToUpload}
                        >
                            <ListSortable
                                ref={listSortableRef}
                                headerStyle={{ padding: unitize(10) }}
                                listStyle={{ display: "grid", gridGap: unitize(5) }}
                                columnSizes={listColumnSizes}
                                items={controller.attachments}
                                columns={[
                                    {
                                        title: "Name",
                                        sortProperty: "imageFilename",
                                    },
                                    {
                                        title: "Typ",
                                        sortFunction: sortFilesByCategory,
                                    },
                                    {
                                        title: "Hochgeladen",
                                        sortProperty: "createdAt",
                                    },
                                ]}
                            >
                                {(sortedList) => <ListFiles sortedList={sortedList} columnSizes={listColumnSizes} />}
                            </ListSortable>
                        </Dropzone>
                    )}
                </div>
            )}
        </>
    );
};

export default observer(BuildingViewFiles);

//#region styles
const SubHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${unitize(30)};
    > h2 {
        margin-right: ${unitize(20)};
    }
`;

const S = {
    AreasHeader: styled(SubHeader)``,
    Description: styled.div`
        margin-top: ${unitize(20)};
        margin-bottom: ${unitize(20)};
        font-size: ${unitize(19)};
    `,
    FilesHeader: styled(SubHeader)`
        margin-top: ${unitize(50)};
    `,
    NothingFound: styled.div`
        padding: ${unitize(100)} 0;
        text-align: center;
        border: 1px solid ${(props) => props.theme.color.grey};
        border-radius: ${(props) => props.theme.borderRadius};
        color: ${(props) => props.theme.color.darkgrey};
        font-size: ${(props) => props.theme.typography.size.h4}rem;
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${unitize(20)};
    `,
};
//#endregion styles

import { MainNavigation, SideModal, unitize } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FileEditForm from "../../components/FileEditForm";
import { getController } from "../../stores/controller/ControllerFactory";
import MainPage from "../../templates/MainPage";
import { isDefined } from "../../utils/isDefined";
import { layout } from "../../utils/layout";
import { PageDepth, useBreadcrumb } from "../../utils/useBreadcrumb";
import { BuildingController } from "./BuildingController";
import BuildingAreas from "./subviews/BuildingAreas";
import BuildingDocumentationSideModal from "./subviews/BuildingDocumentationSideModal";
import BuildingViewFiles from "./subviews/BuildingViewFiles";
import Edit from "./subviews/Edit";
import PdfDocumentations from "./subviews/PdfDocumentations";

enum SubViewKey {
    BuildingAreas = "tab__building-areas",
    BuildingViewFiles = "tab__files",
    PdfDocumentations = "tab__pdf-documentations",
}

/**
 * Page: Building
 */
const Building: FunctionComponent = () => {
    const { controller, useInit } = getController(BuildingController);
    const params: { buildingId: string } = useParams();
    const buildingId = parseInt(params.buildingId);
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const breadcrumb = useBreadcrumb(PageDepth.Building, controller);
    const currentId = controller.currentId;

    const [subView, setSubView] = useState(SubViewKey.BuildingAreas);
    // if there is a file in the url and we are not already in files subview: redirect!
    if (location.pathname.toString().includes("file") && subView !== SubViewKey.BuildingViewFiles) {
        setSubView(SubViewKey.BuildingViewFiles);
    }

    useInit(buildingId);

    //#region render
    return (
        <MainPage
            title={
                isDefined(controller.building)
                    ? isDefined(controller.building.name)
                        ? controller.building.name
                        : "Unbenannt"
                    : "lädt..."
            }
            breadcrumb={breadcrumb}
            onTitleClick={() => history.push(`${url}/edit`)}
            pageType={"building"}
        >
            <Switch>
                <Route path={`${path}/edit`}>
                    <SideModal
                        className="uf-sideModal"
                        isOpen
                        onCloseClick={() => history.push(`${url}`)}
                        style={{
                            top: `${layout.header.height}px`,
                            height: `calc(100% - ${layout.header.height}px)`,
                        }}
                        views={[
                            {
                                component: <Edit selectedBuildingId={currentId} onError={() => Error} />,
                                key: "edit",
                                width: 750,
                            },
                        ]}
                    />
                </Route>
                <Route path={`${path}/file/:id`}>
                    <SideModal
                        className="uf-sideModal"
                        isOpen
                        onCloseClick={() => history.push(`${url}`)}
                        style={{
                            top: `${layout.header.height}px`,
                            height: `calc(100% - ${layout.header.height}px)`,
                        }}
                        views={[
                            {
                                component: (
                                    <FileEditForm
                                        deleteFile={controller.deleteFile.bind(controller)}
                                        files={controller.attachments}
                                        updateFile={controller.updateFile.bind(controller)}
                                        waitingForFiles={controller.isWaitingFor("loadFiles")}
                                        building={controller.building}
                                        reloadUrl={() => controller.loadFiles(true)}
                                        waitingForReloadUrl={controller.isWaitingFor("reloadUrl")}
                                    />
                                ),
                                key: "file-edit",
                                width: 750,
                            },
                        ]}
                    />
                </Route>
                <Route path={`${path}/documentations/:id`}>
                    <SideModal
                        className="uf-sideModal"
                        isOpen
                        onCloseClick={() => history.push(`${url}`)}
                        style={{
                            top: `${layout.header.height}px`,
                            height: `calc(100% - ${layout.header.height}px)`,
                        }}
                        views={[
                            {
                                component: <BuildingDocumentationSideModal />,
                                key: "building-documentation-side-modal",
                                width: 750,
                            },
                        ]}
                    />
                </Route>
            </Switch>
            <MainNavigation
                activeId={subView}
                onActiveChange={(id) => setSubView(id as SubViewKey)}
                items={[
                    {
                        id: SubViewKey.BuildingAreas,
                        text: "Dachflächen",
                        view: (
                            <S.View>
                                <BuildingAreas key="building-areas" />
                            </S.View>
                        ),
                    },
                    {
                        id: SubViewKey.PdfDocumentations,
                        text: "PDF Dokumentationen",
                        disabled: false,
                        view: (
                            <S.View>
                                <PdfDocumentations key="pdf-documentation" type="assembly" />
                            </S.View>
                        ),
                    },
                    {
                        id: SubViewKey.BuildingViewFiles,
                        text: "Dokumentenablage",
                        view: (
                            <S.View>
                                <BuildingViewFiles key="building-view-files" />
                            </S.View>
                        ),
                    },
                ]}
            />
        </MainPage>
    );
};
//#endregion

export default observer(Building);

//#region styles
const S = {
    NothingFound: styled.div`
        padding: ${unitize(100)} 0;
        text-align: center;
        border: 1px solid ${(props) => props.theme.color.grey};
        border-radius: ${(props) => props.theme.borderRadius};
        color: ${(props) => props.theme.color.darkgrey};
        font-size: ${(props) => props.theme.typography.size.h4}rem;
    `,
    View: styled.div`
        margin-top: ${unitize(20)};
    `,
};
//#endregion styles
